<template>
    <div class="bg-[#142552] w-[400px] h-[318px] rounded-[10px] pt-[12px] pb-[27px] border-[1px] border-[#252526]">
        <div class="w-full text-center mb-[20px]">
            <div class="flex text-center items-center py-[4px] bg-[#ABD3EC] w-[95%] rounded-[7px] mx-auto">
                <div class="block-title text-[11px] font-bold text-[#142552] text-center mx-auto w-[60%]">ДОКУМЕНТИ</div>
            </div>
        </div>
        <image-viewer 
            class="absolute z-40 mt-[-20px]"
            v-if="showImageViewer"
            :image="currentImage"
            :photoType="currentPhotoType"
            @close="showImageViewer = false"
            @deletePhoto="$emit('deletePhoto', {currentImage, currentPhotoType})"
        />
        <div class="w-full px-[27px] mt-[-16px]">
        <div class="flex justify-between text-[#ABD3EC] w-full items-center my-[16px] h-[19px]">
            <data-input 
                :modelValue="nameEng"
                @update:modelValue="$emit('update:nameEng', $event)"
                :placeholder="'Name'"
                :blockSize="'30px'"
                :displayStyles="'text-[13px] text-[#ABD3EC] w-[30px] font-semibold text-white w-full bg-transparent border-none'"
                :inputStyles="'text-[#DFF726] text-start text-[13px] p-0 m-0 w-[30px] font-semibold bg-transparent border-none relative top-[-1px] outline-none active:outline-none'"
            />
            <div class="opacity-70 text-[11px]">name</div>
        </div>
        <div class="flex justify-between text-[#ABD3EC] w-full items-center my-[16px] h-[19px]">
            <data-input 
                :modelValue="surnameEng"
                @update:modelValue="$emit('update:surnameEng', $event)"
                :placeholder="'Surname'"
                :blockSize="'100px'"
                :displayStyles="'text-[13px] text-[#ABD3EC] w-[30px] font-semibold text-white w-full bg-transparent border-none'"
                :inputStyles="'text-[#DFF726] text-start text-[13px] p-0 m-0 w-[100px] font-semibold bg-transparent border-none relative top-[-1px] outline-none active:outline-none'"
            />
            <div class="opacity-70 text-[11px]">surname</div>
        </div>
        <div class="flex justify-between text-[#ABD3EC] w-full items-center my-[16px]">
            <data-input 
                :modelValue="passportEndDate"
                @update:modelValue="$emit('update:passportEndDate', $event)"
                placeholder="12.12.2012"
                inputType="date"
                :blockSize="'100px'"
                :displayStyles="'text-[13px] text-[#ABD3EC] w-[100px] font-semibold text-white w-full bg-transparent border-none'"
                :inputStyles="'text-[#DFF726] text-start text-[13px] p-0 m-0 w-[100px] font-semibold bg-transparent border-none relative top-[-1px] outline-none active:outline-none'"
            />
            <div class="opacity-70 text-[11px]">дата закінчення паспорту</div>
        </div>
        <div class="flex justify-between text-[#ABD3EC] w-full items-center my-[16px] h-[19px]">
            <data-input 
                :modelValue="passport"
                @update:modelValue="$emit('update:passport', $event)"
                placeholder="FX123456"
                :blockSize="'100px'"
                :displayStyles="'text-[13px] text-[#ABD3EC] w-[30px] font-semibold text-white w-full bg-transparent border-none'"
                :inputStyles="'text-[#DFF726] text-start text-[13px] p-0 m-0 w-[100px] font-semibold bg-transparent border-none relative top-[-1px] outline-none active:outline-none'"
            />
            <div class="opacity-70 text-[11px]">паспорт</div>
            <div class="text-sm flex">
                <img 
                    v-for="passportPhoto in passportPhotoArray"
                    @click="setImageToView(passportPhoto, 'passport')"
                    src="@/assets/icons/picture_icon.svg" 
                    class="mx-[2px] w-[16px] cursor-pointer"
                >
                <img 
                    @click="uploadPassportPhoto"
                    src="@/assets/icons/upload_icon.svg" 
                    class="w-[16px] ml-2 cursor-pointer"
                >
                <input 
                    @change="$emit('addPassportPhoto', $event.target.files[0])"
                    ref="passportFilesInput" 
                    type="file" 
                    class="hidden"
                >
            </div>
        </div>
        <div class="flex justify-between text-[#ABD3EC] w-full items-center my-[16px] h-[19px]">
            <data-input 
                :modelValue="idNumber"
                @update:modelValue="$emit('update:idNumber', $event)"
                placeholder="0000000000"
                :blockSize="'100px'"
                :displayStyles="'text-[13px] text-[#ABD3EC] w-[30px] font-semibold text-white w-full bg-transparent border-none'"
                :inputStyles="'text-[#DFF726] text-start text-[13px] p-0 m-0 w-[100px] font-semibold bg-transparent border-none relative top-[-1px] outline-none active:outline-none'"
            />
            <div class="opacity-70 text-[11px]">ID код</div>
            <div class="text-sm flex">
                <img 
                    v-for="idNumber in idNumberPhotoArray"
                    @click="setImageToView(idNumber, 'idNumber')"
                    src="@/assets/icons/picture_icon.svg" 
                    class="mx-[2px] w-[16px] cursor-pointer"
                >
                <img 
                    @click="uploadIdNumberPhoto"
                    src="@/assets/icons/upload_icon.svg" 
                    class="w-[16px] ml-2 cursor-pointer"
                >
                <input 
                    @change="$emit('addIdNumberPhoto', $event.target.files[0])"
                    ref="idNumberFilesInput" 
                    type="file" 
                    class="hidden"
                >
            </div>
        </div>
        <div class="flex justify-between text-[#ABD3EC] w-full items-center my-[16px]">
            <div class="font-bold text-[13px]">
                Довіреність
            </div>
            <div class="text-sm flex">
                <img 
                    v-for="attorney in attorneyFileArray" 
                    @click="setImageToView(attorney, 'attorney')"
                    src="@/assets/icons/picture_icon.svg" 
                    class="mx-[2px] w-[16px] cursor-pointer"
                >
                <img 
                    @click="uploadAttorneyPhoto"
                    src="@/assets/icons/upload_icon.svg" 
                    class="w-[16px] ml-2 cursor-pointer"
                >
                <input 
                    @change="$emit('addAttorneyPhoto', $event.target.files[0])"
                    ref="attorneyFilesInput" 
                    type="file" 
                    class="hidden"
                >
            </div>
        </div>
        <div class="flex justify-between text-[#ABD3EC] w-full items-center my-[16px] h-[19px]">
            <data-input 
                :modelValue="patronFirstName"
                @update:modelValue="$emit('update:patronFirstName', $event)"
                placeholder="Name"
                :blockSize="'100px'"
                :displayStyles="'text-[13px] text-[#ABD3EC] w-[80px] font-semibold text-white w-full bg-transparent border-none'"
                :inputStyles="'text-[#DFF726] text-start text-[13px] p-0 m-0 w-[100px] font-semibold bg-transparent border-none relative top-[-1px] outline-none active:outline-none'"
            />
            <div class="opacity-70 text-[11px]">відповідальна особа #1</div>
            <data-input 
                class="text-end"
                :modelValue="patronFirstPhone"
                @update:modelValue="$emit('update:patronFirstPhone', $event)"
                placeholder="+380000000000"
                :blockSize="'100px'"
                :displayStyles="'text-[13px] text-[#ABD3EC] font-semibold text-white w-full bg-transparent border-none'"
                :inputStyles="'text-[#DFF726] text-end text-[13px] p-0 m-0 w-[100px] font-semibold bg-transparent border-none relative top-[-1px] outline-none active:outline-none'"
            />
        </div>
        <div class="flex justify-between text-[#ABD3EC] w-full items-center my-[16px] h-[19px]">
            <data-input 
                :modelValue="patronSecondName"
                @update:modelValue="$emit('update:patronSecondName', $event)"
                placeholder="Name"
                :blockSize="'100px'"
                :displayStyles="'text-[13px] text-[#ABD3EC] w-[80px] font-semibold text-white w-full bg-transparent border-none'"
                :inputStyles="'text-[#DFF726] text-start text-[13px] p-0 m-0 w-[100px] font-semibold bg-transparent border-none relative top-[-1px] outline-none active:outline-none'"
            />
            <div class="opacity-70 text-[11px]">відповідальна особа #2</div>
            <data-input 
                class="text-end"
                :modelValue="patronSecondPhone"
                @update:modelValue="$emit('update:patronSecondPhone', $event)"
                placeholder="+380000000000"
                :blockSize="'100px'"
                :displayStyles="'text-[13px] text-[#ABD3EC] text-end font-semibold text-white w-full bg-transparent border-none'"
                :inputStyles="'text-[#DFF726] text-end text-[13px] p-0 m-0 w-[100px] font-semibold bg-transparent border-none relative top-[-1px] outline-none active:outline-none'"
            />
        </div>
        </div>
    </div>
</template>

<script>
    import {ref} from 'vue'

    import DataInput from '../ui/DataInput.vue'
    import ImageViewer from '../ui/ImageViewer.vue'
    
    export default {
        name: 'documentary-card',

        props: {
            nameEng: {
                type: String,
                default: ''
            },
            surnameEng: {
                type: String,
                default: ''
            },
            passport: {
                type: String,
                default: ''
            },
            passportPhotoArray: {
                type: Array,
                default: []
            },
            idNumber: {
                type: String,
                default: ''
            },
            idNumberPhotoArray: {
                type: Array,
                default: []
            },
            passportEndDate: {
                type: String,
                default: ''
            },
            patronFirstName: {
                type: String,
                default: ''
            },
            patronFirstPhone: {
                type: String,
                default: ''
            },
            patronSecondName: {
                type: String,
                default: ''
            },
            patronSecondPhone: {
                type: String,
                default: ''
            },
            attorneyFileArray: {
                type: Array,
                default: []
            }
        },

        setup() {
            const passportFilesInput = ref(null)
            const idNumberFilesInput = ref(null)
            const attorneyFilesInput = ref(null)
            const currentImage = ref(null)
            const showImageViewer = ref(false)
            const currentPhotoType = ref(null)

            const uploadPassportPhoto = () => {
                passportFilesInput.value.click()
            }

            const uploadIdNumberPhoto = () => {
                idNumberFilesInput.value.click()
            }

            const uploadAttorneyPhoto = () => {
                attorneyFilesInput.value.click()
            }

            const setImageToView = (image, type) => {
                currentImage.value = image
                currentPhotoType.value = type
                showImageViewer.value = true
                console.log(currentPhotoType.value)
            }

            return {
                uploadPassportPhoto,
                passportFilesInput,
                currentImage,
                showImageViewer,
                setImageToView,
                uploadIdNumberPhoto,
                idNumberFilesInput,
                currentPhotoType,
                attorneyFilesInput,
                uploadAttorneyPhoto
            }
        },

        components: {
            DataInput,
            ImageViewer
        }
    }
</script>

<style lang="scss" scoped>
    .block-title { 
        font-family: 'Avenir Cyrillic Bold';
    }
</style>