<template>
    <tr class="w-full border-b-[1px] border-[#252526]">
        <td @click="$emit('openTrainerCard')" class="pl-3 pr-2 py-2 text-start text-sm w-[5%] cursor-pointer">
            <div class="w-10 h-10 rounded-full overflow-hidden flex items-end border-[#FFFFFF] border-[1px]">
                <img v-if="trainer.photo" :src="apiURL + 'static/' + trainer.photo">
                <div v-else class="w-full h-full text-[#FFFFFF] flex justify-center items-center">
                    {{ trainer.name[0] + trainer.surname[0] }}
                </div>
            </div>
        </td>
        <td @click="$emit('openTrainerCard')" class="px-3 py-2 text-start text-sm w-[15%] cursor-pointer">
            <div class="flex flex-col">
                <div class="truncate text-[#FFFFFF]">{{ trainer.surname }} {{ trainer.name[0] + '.' }}</div>
                <div class="truncate text-sm text-gray-400">{{ trainer.phone }}</div>
            </div>
        </td>
        <td class="px-3 py-2 truncate w-[15%] text-start text-sm text-[#FFFFFF]">
            <span v-if="typeof groups != 'null'">{{ groups ? groups.join(', ').length > 20 ? groups.join(', ').slice(0, 20) + '...' : groups.join(', ') : '—' }}</span>
            <Skeletor v-else width="70" height="15" pill as="div" :shimmer="true"/>
        </td>
        <td class="px-3 py-2 text-end text-sm w-[15%] text-[#FFFFFF]">
            <span v-if="typeof activeStudentsCount == 'number' ">{{ activeStudentsCount }}</span>
            <Skeletor v-else width="40" class="ml-auto" height="15" pill as="div" :shimmer="true"/>
        </td>
        <td class="px-3 py-2 text-end text-sm w-[10%]"></td>
        <td class="px-3 py-2 text-center text-sm w-[25%]">
            <div class="flex justify-center" v-for="s in ['active',]">
                <div v-if="s == 'active'" class="bg-[#4EAE3B] mx-1 h-[18px] pb-[2px] flex justify-center items-center rounded-full w-[88px] text-[13px] text-center text-[#fff]">
                    <div>
                        Активний
                    </div>
                </div>  
                <div v-if="s == 'archive'" class="bg-[#4EAE3B] mx-1 h-[18px] pb-[2px] flex justify-center items-center rounded-full w-[88px] text-[13px] text-center text-[#fff]">
                    Архів
                </div> 
            </div>
        </td>
        <td class="px-3 py-2 select-none text-end text-sm w-[15%]">
            <button @click="$emit('openTrainerCard')" class="px-2 py-1 rounded-[10px] text-sm font-medium">
                <img src="@/assets/icons/pen_icon.svg" class="w-6 h-6">
            </button>
            <button @click="$emit('deleteTrainer', trainer)" class="px-2 py-1 rounded-[10px] text-sm font-medium text-red-600">
                <img src="@/assets/icons/trash_icon.svg" class="w-6 h-6">
            </button>
        </td>
    </tr>
</template>

<script>
    import { ref, onMounted } from 'vue'
    import { getGroupsByTrainerId, getActiveStudentsInGroup } from '@/services/apiRequests'
    import { Skeletor } from 'vue-skeletor'

    export default {
        name: 'trainer-row',

        props: {
            trainer: {
                type: Object,
                required: true
            }
        },

        setup(props, { emit }) {
            const apiURL = ref(process.env.VUE_APP_API_URL)
            const groups = ref(null)
            const activeStudentsCount = ref(null)

            const getActiveStudentByEachGroup = async (groups) => {
                let sum = 0
                for (let i = 0; i < groups.length; i++) {
                    const res = await getActiveStudentsInGroup(groups[i].group_id)
                    sum += res.data
                }
                emit('addCountStudents', sum)
                return sum
            }

            onMounted(async () => {
                const res = await getGroupsByTrainerId(props.trainer.trainer_id)
                groups.value = res.data.map(g => g.name)
                activeStudentsCount.value = await getActiveStudentByEachGroup(res.data)
            })

            return {
                apiURL,
                groups,
                activeStudentsCount
            }
        },

        components: {
            Skeletor
        }
    }
</script>

<style lang="scss" scoped>

    .vue-skeletor {
        background-color: #707070;
        animation: shimmer 1.5s infinite;
        animation-duration: 2s; 
        animation-timing-function: ease-in-out;
        border-radius: 10px;
    }

    /* Default keyframes used in skeletor */
    @keyframes shimmer {
        0% {
            opacity: .5;
            
        }
        50% {
            opacity: 1;
            
        }
        100% {
            opacity: .5;
            
        }
    }

</style>