<template>
    <div>
        <TransactionFilterPopup 
            v-if="showPopup"
            :filters = "filtersModel"
            @close="showPopup = false"
            @setFilters="filters => setFilters(filters)"
            @clearFilters="clearFilters"
        />
        <ImageViewer 
            v-if="showPictureFlag"
            :imageUrl="showPictureUrl"
            :altText="showPictureUrl"
            @close="showPictureFlag = false"
        />
        <NewTransaction 
            v-if="showNewTransactionFlag"
            @close="showNewTransactionFlag = false; refreshTx()"
        />
        <div class="mt-[10px] flex justify-between w-[90%] items-center mx-auto mb-[30px]">
            <div>
                <button @click="createTransaction" class="bg-gray-500 px-2 align-middle rounded-md text-sm py-[2px] ml-[5px] hover:bg-opacity-90 text-white ">
                    Створити транзакцію
                </button>  
            </div>
            <div class="flex justify-end">
                <!-- <div>
                    <span class="text-[#FFFFFF]">Кількість транзакцій:</span>
                    <input v-model="countOfTransactions" type="number" class="w-[50px] text-[#FFFFFF] outline-none ml-2 bg-transparent rounded-md pl-[5px]">
                </div> -->
                <div class="flex w-[110px] justify-between mr-[130px] text-[#FFFFFF]">
                    <img @click="prevPage" src="@/assets/icons/left_arrow.svg" class="cursor-pointer">
                    <div class="flex mx-[20px]">Сторінка: <input v-model="currentPage" type="number" class="bg-transparent mx-2 w-[5ch] outline-none px-1"> / <span class="ml-[10px]">{{ maxPage }}</span></div>
                    <img @click="nextPage" src="@/assets/icons/right_arrow.svg" class="cursor-pointer">
                </div>
                <!-- <div>
                    <button @click="showPopup = true" class="bg-gray-500 px-2 rounded-md text-sm py-[2px] ml-[5px] hover:bg-opacity-90 text-white ">
                        Фільтри
                    </button>    
                </div> -->
            </div>
        </div>

        <div class="w-[90%] mx-auto mt-3 mb-[20px] rounded-[10px] overflow-auto" :key="tableObjectKey">
            <table class="w-full">
                <thead class="select-none">
                    <tr class="text-white border-b-[1px] border-[#252526]">
                        <th class="px-3 py-2 text-start w-[10%] font-medium text-sm">Дата</th>
                        <th class="px-3 py-2 text-start w-[20%] font-medium text-sm">Відправник</th>
                        <th class="px-3 py-2 text-start w-[15%] font-medium text-sm"></th>
                        <th class="px-3 py-2 text-start w-[20%] font-medium text-sm">Отримувач</th>
                        <th class="px-3 py-2 text-start w-[15%] font-medium text-sm">Сума</th>
                        <th class="px-3 py-2 text-start w-[15%] font-medium text-sm"></th>
                        <th class="px-3 py-2 text-center font-medium text-sm"></th>
                    </tr>
                </thead>
                <tbody :key="txLogKey"> 
                    <transaction-row 
                        v-for="transaction in transactions" 
                        :transaction="transaction"
                    />
                </tbody>
            </table>
        </div>

    </div>
</template>

<script>
    import { uuid } from 'vue3-uuid'
    import { useRouter, useRoute } from 'vue-router'
    import { ref, onMounted, watch, onUpdated } from 'vue'
    import { getAllTransactions } from '@/services/apiRequests'
    import TransactionRow from './components/TransactionRow.vue'
    import TransactionFilterPopup from './components/TransactionFilterPopup.vue'
    import ImageViewer from './ui/ImageViewer.vue'
    import NewTransaction from './components/NewTransaction.vue'

    export default {
        name: 'Transactions', 

        setup() {
            const router = useRouter()
            const route = useRoute()

            const txLogKey = ref(uuid.v4())

            const transactions = ref([])

            const showNewTransactionFlag = ref(false)

            const loading = ref(true)

            const currentPage = ref(1)
            const maxPage = ref(1)

            const refreshTx = async () => {
                loading.value = true
                const transactionRes = await getAllTransactions()
                transactions.value = []
                transactions.value = transactionRes.transactions
                txLogKey.value = uuid.v4()
                loading.value = false
            }

            onMounted(async () => {
                const transactionRes = await getAllTransactions()
                transactions.value = transactionRes.transactions
                loading.value = false
            })

            const createTransaction = () => {
                showNewTransactionFlag.value = true
            }

            return {
                transactions,
                createTransaction,
                showNewTransactionFlag,
                refreshTx,
                txLogKey,
                loading,
                currentPage,
                maxPage
            }
        },

        components: {
            TransactionRow,
            TransactionFilterPopup,
            ImageViewer,
            NewTransaction
        }
    }
</script>

<style lang="scss" scoped>

input::-webkit-outer-spin-button, input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
}

</style>