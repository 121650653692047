<template>
    <div class="mt-[11px]">
        <div class="flex items-center justify-between mx-[30px]">
            <div class="text-white flex items-center text-[12px] font-semibold">
                <div class="mr-[5px]">
                    оплати
                </div>
                <div @click="$router.push('/incomes/balance_id/' + balanceId)" class="px-4 py-0.5 cursor-pointer bg-[#3B9BF4] text-[12px] text-center text-black font-bold rounded-md ">
                    переглянути
                </div>
            </div>
            <div class="px-4 py-0.5 bg-[#4EAE3B] text-[12px] text-center text-black font-bold rounded-md ">
                {{ currentYear }}
            </div>
        </div>
        <div class="scrolltab w-[95%] mt-[9px] flex flex-col items-center overflow-auto h-[80px]">
            
            <div v-for="income in mappedIncomes" class="flex mt-2 justify-between text-[#FFF] items-center w-[80%]">
                <div class="mr-2 w-[30px] text-[10px]">{{ income.dateParsed }}</div>
                <div class="mr-2 w-[30px] text-[10px]">{{ income.timeParsed }}</div>
                <div class="text-[12px] w-[30px] font-bold">{{ income.source }}</div>
                <div class="mr-[3px] w-[30px] text-end font-bold text-[12px]">{{ income.amount }}</div>
            </div>

            
            <!-- <div class="left-block ">
                <div class="flex mb-[7px] justify-between text-[#ABD3EC] items-center">
                    <div class="mr-2 text-[10px]">21.21.2021</div>
                    <div class="mr-2 text-[10px]">15:00</div>
                    <div class="mr-[3px] font-bold text-[12px]">650.00</div>
                    <div class="text-[10px]">(card)</div>
                </div>  
                
            </div>
            <div class="divider h-[139px] w-[1px] mx-[12px] bg-[#ABD3EC] opacity-25"></div>
            <div class="right-block ">
                <div class="flex mb-[7px] w-full justify-between text-[#ABD3EC] items-center">
                    <div class="mr-2 text-[10px]">21.21.2021</div>
                    <div class="mr-2 text-[10px]">15:00</div>
                    <div class="mr-[3px] font-bold text-[12px]">650.00</div>
                    <div class="text-[10px]">(card)</div>
                </div>  
            </div> -->
        </div>
    </div>
</template>

<script>
    import { ref, onMounted, watch } from 'vue';

    export default {
        name: 'income-accounting',

        props: {
            studentIncomes: {
                type: Array,
                default: () => []
            },
            balanceId: {
                type: String,
                default: ''
            }
        },

        setup(props) {
            const currentYear = new Date().getFullYear()
            const mappedIncomes = ref([])
            
            onMounted(() => {
                mapIncomes(props.studentIncomes)
            })

            const addDigits = (value) => {
                return value.toString().length == 1 ? '0' + value : value
            }

            const parseDate = (unparsedDate) => {
                return unparsedDate.split('T')[0].split('-').reverse().join('.')
            }

            const parseTime = (unparsedDate) => {
                const dateObj = new Date(unparsedDate)
                const hours = addDigits(dateObj.getHours())
                const minutes = addDigits(dateObj.getMinutes())
                return `${hours}:${minutes}`
            } 

            const getSource = (income) => {
                return income.label.includes('Cashbox') ? 'готівка' : 'безготівка'
            }

            const mapIncomes = (incomes) => {
                incomes.forEach(income => {
                    mappedIncomes.value.push(
                        {
                            dateParsed: parseDate(income.date),
                            timeParsed: parseTime(income.date),
                            amount: income.amount,
                            source: getSource(income)
                        }
                    )
                })
            }

            watch(() => props.studentIncomes, (incomes) => {
                mapIncomes(incomes)
            })

            return {
                currentYear,
                mappedIncomes
            }
        }
    }
</script>

<style lang="scss" scoped>

    .scrolltab::-webkit-scrollbar {
        width: 5px;               /* ширина всей полосы прокрутки */
    }

    .scrolltab::-webkit-scrollbar-track {
        background: transparent;        /* цвет зоны отслеживания */
    }

    .scrolltab::-webkit-scrollbar-thumb {
        background-color: #252526;    /* цвет бегунка */
        border-radius: 20px;       /* округлось бегунка */ /* отступ вокруг бегунка */
    }

</style>