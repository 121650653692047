<template>
    <div @click="exitFlag = true" style="background-color: rgba(29, 29, 29, .35)" class="absolute w-full h-full "></div>
    <ExitConfirmForm
        class="absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]"
        v-if="exitFlag"
        :alertText="'Зберегти зміни для ' + newAdmin.admin_name + ' ' + newAdmin.admin_surname + '?'"
        @dontSave="() => $emit('close')"
        @doSave="saveAdmin"
        @returnToCurrent="exitFlag = false"
    />
    <div v-else class="absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] ml-[100px]">
        <AdminCard 
            v-model:name="newAdmin.admin_name"
            v-model:surname="newAdmin.admin_surname"
            :cashBalance="0"
            :investBalance="0"
            v-model:birthDate="newAdmin.birthday"
            v-model:email="newAdmin.email"
            v-model:phone="newAdmin.phone"
            v-model:login="newAdmin.admin_login"
            v-model:password="newAdmin.admin_password"
        />
    </div>
</template>

<script>
    import { ref } from 'vue'
    import { addNewAdmin } from '@/services/apiRequests'
    import AdminCard from './AdminCard.vue'
    import ExitConfirmForm from './ui/ExitConfirmForm.vue'
    export default {
        name: 'new-admin-form',

        emits: ['close'],

        setup(props, { emit }) {
            const newAdmin = ref({
                admin_name: '',
                admin_surname: '',
                birthday: new Date().toISOString().split('T')[0],
                email: '',
                phone: '',
                admin_login: '',
                admin_password: ''
            })
            const exitFlag = ref(false)

            const saveAdmin = async () => {
                console.log('newAdmin.value ------ ', newAdmin.value)
                newAdmin.value.birthday = new Date(newAdmin.value.birthday).toISOString()
                await addNewAdmin(newAdmin.value)
                exitFlag.value = true
                emit('close')
                emit('update-admins')
            }

            return {
                newAdmin,
                exitFlag,
                saveAdmin
            }
        },

        components: {
            AdminCard,
            ExitConfirmForm
        }
    }
</script>

<style lang="scss" scoped>

</style>