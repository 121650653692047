<template>
  <div class="flex flex-col justify-center">
    <div class="relative w-max h-max">
      <div class="" ref="info">
        <div v-if="activeSegment" class="absolute top-[30px] p-2 ">
          <div class="text-[14px] truncate text-[#F9245A]">{{ activeSegment.label }}</div>
          <div class="text-gray-400">{{ activeSegment.value }} ({{ activeSegment.percent }}%)</div>
        </div>
      </div>
      <canvas ref="canvas" :width="220" :height="220" class="mt-[70px] cursor-pointer" @mousemove="handleMouseMove"></canvas>
      <div v-if="maxSegmentValue < 0" class="w-[330px] absolute top-0 right-[-55px] h-[300px] flex justify-center items-center">
        <div class="absolute w-[200px] h-[200px] rounded-full border-[40px] border-[#252526] opacity-25"></div>
        <div class="text-gray-200">Нема даних</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    width: {
      type: Number,
      default: 220,
    },
    height: {
      type: Number,
      default: 220,
    },
    segments: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      activeSegment: null,
      maxSegmentValue: 0,
    }
  },

  updated() {
    this.$refs.canvas.focus()
  },

  mounted() {
    const maxSegmentValue = Math.max(...this.segments.map((el) => el.value))
    console.log('maxSegmentValue ---- ', maxSegmentValue)
    const segmentsWithValues = this.segments.filter((el) => el.value > 0)
    this.maxSegmentValue = maxSegmentValue
    console.log('this.maxSegmentValue ---- ', this.maxSegmentValue)
    if (segmentsWithValues.length > 0 && maxSegmentValue > 0) {
      console.log('segmentsWithValues.length -------- ', segmentsWithValues)
      const canvas = this.$refs.canvas
      const ctx = canvas.getContext('2d')
      const radius = canvas.height / 3
      const lineWidth = 36
      let gap = 0.02
      if (segmentsWithValues.length <= 1) {
        gap = 0
      }
      console.log(segmentsWithValues)
      const sumEls = segmentsWithValues.reduce((acc, el) => acc + el.value, 0)
  
      const fullCircleRadians = 6.28
      const fullCircleWithGap = fullCircleRadians - (segmentsWithValues.length * gap)
  
      let startAngle = 0
  
      for (let i = 0; i < segmentsWithValues.length; i++) {
        const valuePercent = (segmentsWithValues[i].value * 100) / sumEls
        const widthSegment =  (fullCircleWithGap * valuePercent) / 100
        
        if (segmentsWithValues[i].value > 0) {
          ctx.beginPath()
          ctx.arc(
            canvas.width / 2,
            canvas.height / 2,
            radius,
            startAngle,
            startAngle + widthSegment - gap,
            false
          )
          ctx.lineWidth = lineWidth
          ctx.strokeStyle = `#F9245A`;
          ctx.stroke()
          startAngle += widthSegment + gap
        }
      }
  
      this.activeSegment = {
        label: segmentsWithValues[0].label,
        value: segmentsWithValues[0].value,
        percent: ((segmentsWithValues[0].value / segmentsWithValues.reduce((acc, seg) => acc + seg.value, 0)) * 100).toFixed(1),
      }
    }
  },
  methods: {
    getActiveSegmentId(event) {
      const canvas = this.$refs.canvas;
      const rect = canvas.getBoundingClientRect();
      const x = event.clientX - rect.left;
      const y = event.clientY - rect.top;
      const distanceFromCenter = Math.sqrt(Math.pow(x - canvas.width / 2, 2) + Math.pow(y - canvas.height / 2, 2));
      
      if (distanceFromCenter < 200) {
        let angle = Math.atan2(y - canvas.height / 2, x - canvas.width / 2);
        if (angle < 0) {
          angle += 2 * Math.PI;
        }
        let segmentIndex = 0;
        for (let i = 0; i < this.segments.length; i++) {
          const startAngle = (i === 0) ? 0 : (this.segments.slice(0, i).reduce((acc, seg) => acc + seg.value, 0) / this.segments.reduce((acc, seg) => acc + seg.value, 0)) * 2 * Math.PI;
          const endAngle = startAngle + (this.segments[i].value / this.segments.reduce((acc, seg) => acc + seg.value, 0)) * 2 * Math.PI;
          if (angle >= startAngle && angle < endAngle) {
            return i;
          }
        }
      } else {
        return null;
      }
    },

    handleMouseMove(event) {
      const maxSegmentValue = Math.max(...this.segments.map((el) => el.value))
      const segmentsWithValues = this.segments.filter((el) => el.value > 0)
      this.maxSegmentValue = maxSegmentValue
      if (this.segments.length === 0 || maxSegmentValue === 0) {
        return
      }
      const canvas = this.$refs.canvas;
      const ctx = canvas.getContext('2d')
      const rect = canvas.getBoundingClientRect();
      const x = event.clientX - rect.left;
      const y = event.clientY - rect.top;
      const distanceFromCenter = Math.sqrt(Math.pow(x - canvas.width / 2, 2) + Math.pow(y - canvas.height / 2, 2));
      ctx.clearRect(0, 0, canvas.width, canvas.height)
      if (distanceFromCenter < 200) {
        let angle = Math.atan2(y - canvas.height / 2, x - canvas.width / 2);
        if (angle < 0) {
          angle += 2 * Math.PI;
        }
        let segmentIndex = 0;
        for (let i = 0; i < this.segments.length; i++) {
          const startAngle = (i === 0) ? 0 : (this.segments.slice(0, i).reduce((acc, seg) => acc + seg.value, 0) / this.segments.reduce((acc, seg) => acc + seg.value, 0)) * 2 * Math.PI + i * 0.02;
          const endAngle = startAngle + (this.segments[i].value / this.segments.reduce((acc, seg) => acc + seg.value, 0)) * 2 * Math.PI - 0.02;
          if (angle >= startAngle && angle < endAngle) {
            segmentIndex = i;
            break;
          }
        }
        this.activeSegment = {
          label: this.segments[segmentIndex].label,
          value: this.segments[segmentIndex].value,
          percent: ((this.segments[segmentIndex].value / this.segments.reduce((acc, seg) => acc + seg.value, 0)) * 100).toFixed(1),
        };
      } else {
        this.activeSegment = null;
      }

      const angle = Math.atan2(y - canvas.height / 2, x - canvas.width / 2)
      
      const segmentIndex = this.getActiveSegmentId(event)
      console.log('segmentIndex ---- ', segmentIndex)
      if (segmentIndex >= 0) {
        const segment = this.segments[segmentIndex]
        const percent = ((segment.value / this.segments.reduce((acc, seg) => acc + seg.value, 0)) * 100).toFixed(1)

        this.activeSegment = {
          label: segment.label,
          value: segment.value,
          percent: percent,
        }

        const ctx = canvas.getContext('2d')
        ctx.clearRect(0, 0, canvas.width, canvas.height)
        console.log('ctx.clearRect', canvas.width, canvas.height)

        const radius = canvas.height / 3
        const lineWidth = 36 //canvas.width * 0.1
        let gap = 0.02
        console.log('segmentsWithValues.length ------ ', segmentsWithValues.length)
        if (segmentsWithValues.length <= 1) {
          gap = 0
        }
        const sumEls = this.segments.reduce((acc, el) => acc + el.value, 0)
        const fullCircleRadians = 6.28
        const fullCircleWithGap = fullCircleRadians - (this.segments.length * gap)
        let startAngle = 0

        for (let i = 0; i < this.segments.length; i++) {
          const valuePercent = (this.segments[i].value * 100) / sumEls
          const widthSegment =  (fullCircleWithGap * valuePercent) / 100

          if (this.segments[i].value > 0) {
            ctx.beginPath()
            ctx.arc(
              canvas.width / 2,
              canvas.height / 2,
              radius,
              startAngle,
              startAngle + widthSegment - gap,
              false
            )
  
            if (i === segmentIndex) {
              ctx.globalAlpha = 0.6
            }
  
            ctx.lineWidth = lineWidth
            ctx.strokeStyle = `#F9245A`;
            ctx.stroke()
  
            if (i === segmentIndex) {
              ctx.globalAlpha = 1.0
            }
  
            startAngle += widthSegment + gap
            ctx.closePath()
          }
        }
      }
    },

  },
}
</script>

