import { createApp } from 'vue'
import { createPinia } from 'pinia'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import Vue3TouchEvents from "vue3-touch-events"

const pinia = createPinia()
const app = createApp(App)

app.use(router).use(pinia).use(Vue3TouchEvents).mount('#app')
