<template>
    <div style="background-color: rgba(29, 29, 29, .35)" class="w-[100%] top-[0] ml-[-20px] h-full backdrop-blur-[7px] fixed z-[130]"></div>
    <image-viewer 
        class="absolute z-[150] top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] ml-[120px]"
        v-if="showImageViewer"
        :image="currentImage"
        @close="showImageViewer = false"
        @deletePhoto=""
    />
    <div v-if="loadingSpinnerFlag" class="z-[150] fixed w-full h-full top-[50%] left-[50%] ml-[100px]">
        <div class="w-10 h-10 rounded-full animate-spin border-solid border-4 border-[#F9245A] border-t-transparent "></div>
    </div>
    <div v-else-if="showImageViewer == false && loadingSpinnerFlag == false" class="bg-[#2B2A29] w-[254px] fixed top-[50%] left-[50%] ml-[110px] border-[1px] border-[#47484966] translate-x-[-50%] translate-y-[-50%] z-[140] px-[16px] py-5 rounded-[10px] select-none">
        <div class="">
            <div>
                <div class="flex justify-center items-center">
                    <div class="border-[#8C8C8C] bg-[#474849] bg-opacity-20 border-[1px] w-full items-center flex justify-center relative rounded-[4px] px-[4px]">
                        <img v-if="trainingType == 'training'" src="@/assets/icons/green_ball.svg" class="h-[18px] relative right-[2px]">
                        <img v-else-if="trainingType == 'gym'" src="@/assets/icons/red_dumbbell.svg" class="h-[18px]">
                        <img v-else-if="trainingType == 'competition'" src="@/assets/icons/gold_cup.svg" class="h-[18px]">
                        <img v-else src="@/assets/icons/green_ball.svg" class="h-[18px] relative right-[2px]">
                        <select v-model="trainingType" class="bg-transparent text-[#fff] outline-none text-center  placeholder-gray-500 py-[3px] w-full disabled:opacity-40" >
                            <option value="null" class="bg-[#1D1D1D]">Не задано</option>
                            <option value="training" class="bg-[#1D1D1D]">Тренування</option>
                            <option value="gym" class="bg-[#1D1D1D]">Спортзал</option>
                            <option value="competition" class="bg-[#1D1D1D]">Змагання</option>
                        </select>
                    </div>
                </div>
            </div>
        </div>

        <div class="mt-5">
            <div>
                <div class="flex justify-center items-center bg-[#474849] bg-opacity-20">
                    <input :disabled="addCurrentDateFlag" v-model="changeDate" class="custom-date-input bg-transparent text-[#fff] text-center outline-none border-[1px] border-[#8C8C8C] bg-[#474849] bg-opacity-20 placeholder-gray-500 rounded-[4px] py-[1px] px-[3px] w-full disabled:opacity-40" type="date">
                </div>
            </div>
        </div>

        <div class="mt-5">
            <div class="flex justify-center items-center">
                <div class="bg-[#474849] bg-opacity-20">
                    <input v-model="trainingTime.start" class="custom-time-input bg-transparent text-[#fff] outline-none border-[1px] border-[#8C8C8C] bg-[#474849] bg-opacity-20 placeholder-gray-500 rounded-[4px] py-[2px] px-1 w-full disabled:opacity-40" type="time">
                </div>
                <span class="font-bold mx-2 text-[#ABD3EC]">></span>
                <div class="bg-[#474849] bg-opacity-20">
                    <input v-model="trainingTime.end" class="custom-time-input bg-transparent text-[#fff] outline-none border-[1px] border-[#8C8C8C] bg-[#474849] bg-opacity-20 placeholder-gray-500 rounded-[4px] py-[2px] px-1 w-full disabled:opacity-40" type="time">
                </div>
            </div>
        </div>

        <div class="mt-5">
            <div>
                <div class="">
                    <multi-select 
                        @update="trainingRepeat = $event"
                        @enable="addCurrentDateFlag = false"
                        @disable="addCurrentDateFlag = true"
                    />
                </div>
            </div>
        </div>

        <div class="mt-5">
            <div>
                <div class="flex justify-center items-center">
                    <div class="w-full">
                        <div class="bg-[#474849] bg-opacity-20">
                            <input
                                @change="changeGroupText($event.target.value)"
                                :value="groupText"
                                @focus="groupOptionsShow = true"
                                @blur="groupOptionsShow = false" 
                                class="bg-transparent text-[#fff] text-[14px] text-center outline-none border-[1px] border-[#8C8C8C] bg-[#474849] bg-opacity-20 placeholder-gray-500 rounded-[4px] py-[3px] w-full disabled:opacity-40" 
                                type="text"
                                placeholder="група"
                            />
                        </div>
                        <div v-if="groupOptionsShow" class="z-[150] overflow-auto absolute max-h-[100px] w-[230px] border-[1px] border-[#8C8C8C] bg-[#474849] bg-opacity-20">
                            <div v-for="groupOption in groupVisibleOptions" @mousedown="setGroup(groupOption)" class="w-full px-1 bg-[#8C8C8C] hover:bg-[#8C8C8C] hover:bg-opacity-80 cursor-pointer text-[#000]">{{groupOption.name}}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="mt-5">
            <div>
                <div class="flex justify-center items-center">
                    <div class="w-full">
                        <div class="bg-[#474849] bg-opacity-20">
                            <input
                                @change="changeLocationText($event.target.value)"
                                :value="locationText"
                                @focus="locationOptionsShow = true"
                                @blur="locationOptionsShow = false" 
                                class="bg-transparent text-[#fff] text-[14px] outline-none border-[1px] border-[#8C8C8C] bg-[#474849] bg-opacity-20 placeholder-gray-500 text-center rounded-[4px] py-1 px-[3px] w-full disabled:opacity-40" 
                                type="text"
                                placeholder="локація"
                            />
                        </div>
                        <div v-if="locationOptionsShow" class="z-[150] overflow-auto absolute max-h-[100px] w-[230px] bg-[#142552]">
                            <div v-for="locationOption in locationVisibleOptions" @mousedown="setLocation(locationOption)" class="w-full px-1 bg-[#8C8C8C] hover:bg-[#8C8C8C] hover:bg-opacity-80 cursor-pointer text-[#000]">{{locationOption.name}}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="mt-5">
            <div>
                <div class="flex justify-center items-center">
                    <div class="w-full flex flex-col items-center relative">
                        <div class="bg-[#474849] bg-opacity-20 w-full">
                            <textarea
                                v-model="description"
                                class="bg-transparent text-[#fff] text-[14px] outline-none border-[1px] border-[#8C8C8C] bg-[#474849] bg-opacity-20 placeholder-gray-500 rounded-[4px] p-1 w-full disabled:opacity-40" 
                                type="text"
                                rows="2"
                                placeholder="Коментар до тренування"
                            ></textarea>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="mt-2">
            <div>
                <div class="flex justify-center items-center">
                    <div class="border-[#8C8C8C] bg-[#474849] bg-opacity-20 border-[1px] w-full items-center flex justify-center relative rounded-[4px] px-[4px]">
                        <select :value="trainerId" @change="changeTrainer" class="bg-transparent text-[#fff] outline-none text-center  placeholder-gray-500 py-[3px] w-full disabled:opacity-40" >
                            <option value="" class="bg-[#1D1D1D]">Тренер не заданий</option>
                            <option v-for="trainer in allTrainers" :value="trainer.trainer_id" class="bg-[#1D1D1D]">{{ trainer.name + ' ' + trainer.surname }}</option>
                        </select>
                    </div>
                </div>
            </div>
        </div>

        <div class="mt-5 flex items-center justify-between">
            <div class="flex">
                <div v-if="additionalFiles.length == 0" class="text-white opacity-25">Файли незакріплені</div>
                <div v-for="file in additionalFiles" @click="showImageViewer = true; currentImage = file" class="cursor-pointer mr-[5px]">
                    <img src="@/assets/icons/picture_icon.svg" alt="">
                </div>
            </div>
            <div>
                <img src="@/assets/icons/upload_icon_light.svg" class="cursor-pointer" @click="uploadAdditionalPhoto">
                <input 
                    ref="fileInput"
                    type="file" 
                    class="hidden"
                    @change="additionalFiles.push($event.target.files[0])"
                />
            </div>
        </div>

        <div class="mt-5">
            <button @click="sendData" class="bg-[#4EAE3B] text-[#fff] text-center w-full rounded-md py-1 hover:bg-opacity-90">Зберегти</button>
        </div>
        <div class="mt-2">
            <button @click="$emit('close')" class="bg-[#3B9BF4] text-[#1D1D1D] text-center w-full rounded-md py-1 hover:bg-opacity-90">Закрити</button>
        </div>
        
    </div>
</template>

<script>
    import { ref, computed, reactive, onBeforeMount } from 'vue'
    import { getAllLocations, getAllGroups, addNewTraining, getAllTrainers } from '@/services/apiRequests'
    import MultiSelect from './MultiSelect.vue'
    import FileView from '../ui/FileView.vue'
    import ImageViewer from '../ui/ImageViewer.vue'

    async function compressImageToBlobAsync(file, maxWidth, maxHeight, quality) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = function () {
            const img = new Image();
            img.src = reader.result;
            img.onload = function () {
                let width = img.width;
                let height = img.height;

                if (width > maxWidth) {
                height *= maxWidth / width;
                width = maxWidth;
                }

                if (height > maxHeight) {
                width *= maxHeight / height;
                height = maxHeight;
                }

                const canvas = document.createElement('canvas');
                const ctx = canvas.getContext('2d');
                canvas.width = width;
                canvas.height = height;

                ctx.drawImage(img, 0, 0, width, height);

                // Получаем Blob сжатого изображения
                canvas.toBlob(
                function (blob) {
                    if (blob) {
                    // Создаем новый объект File на основе Blob
                    const compressedFile = new File([blob], file.name, { type: 'image/jpeg' });
                    resolve(compressedFile);
                    } else {
                    reject(new Error('Ошибка при создании Blob'));
                    }
                },
                'image/jpeg',
                quality / 100
                );
            };

            img.onerror = function () {
                reject(new Error('Ошибка при загрузке изображения'));
            };
            };

            reader.onerror = function () {
            reject(new Error('Ошибка при чтении файла'));
            };

            reader.readAsDataURL(file);
        });
    }

    export default {
        name: 'new-training-form',

        props: {
            date: {
                type: Date,
                default: '2022-12-12',
                required: true
            }
        },

        setup(props, { emit }) {
            const settedDate = new Date(props.date)
            const settedYear = settedDate.getFullYear() >= 10 ? settedDate.getFullYear() : `0${settedDate.getFullYear()}`
            const settedMonth = settedDate.getMonth() + 1 >= 10 ? settedDate.getMonth() + 1 : `0${settedDate.getMonth() + 1}`
            const settedDay = settedDate.getDate() >= 10 ? settedDate.getDate() : `0${settedDate.getDate()}`
            const changeDate = ref(`${settedYear}-${settedMonth}-${settedDay}`)

            const trainingRepeat = ref([])
            const addCurrentDateFlag = ref(false)

            const group = ref(null)
            const groupText = ref('')
            
            const location = ref(null)
            const locationText = ref('')

            const groupOptions = ref([])
            const locationOptions = ref([])

            const loadingSpinnerFlag = ref(false)

            const trainingType = ref('null')

            const trainerId = ref('')
            const trainerLabel = ref('')
            const allTrainers = ref([])

            const additionalFiles = ref([])
            const fileInput = ref(null)

            const showImageViewer = ref(false)
            const currentImage = ref(null)

            const description = ref('')

            const uploadAdditionalPhoto = () => {
                fileInput.value.click()
            }

            onBeforeMount(async () => {
                const resGroups = await getAllGroups()
                groupOptions.value = resGroups.data

                const resLocations = await getAllLocations()
                locationOptions.value = resLocations.data

                const resTrainers = await getAllTrainers()
                allTrainers.value = resTrainers.data
            })

            const groupVisibleOptions = computed(() => {
                return groupOptions.value.filter(option => option.name.toLowerCase().includes(groupText.value.toLowerCase()) || groupText.value === '')
            })

            const locationVisibleOptions = computed(() => {
                return locationOptions.value.filter(option => option.name.toLowerCase().includes(locationText.value.toLowerCase()) || locationText.value === '')
            })

            const changeGroupText = (groupName) => {
                groupText.value = groupName
                group.value = groupVisibleOptions.value[0]
            }

            const changeLocationText = (locationName) => {
                locationText.value = locationName
                location.value = locationVisibleOptions.value[0]
            }

            const setLocation = (locationOption) => {
                console.log('location', location)
                location.value = locationOption
                console.log('location', location.value)
                locationText.value = locationOption.name
                locationOptionsShow.value = false
            }

            const setGroup = (groupOption) => {
                group.value = groupOption;
                groupText.value = groupOption.name;
                trainerId.value = groupOption.trainer_id
                trainerLabel.value = allTrainers.value.find(trainer => trainer.trainer_id == groupOption.trainer_id).name + ' ' + allTrainers.value.find(trainer => trainer.trainer_id == groupOption.trainer_id).surname
                groupOptionsShow.value = false;
            }

            const locationOptionsShow = ref(false)
            const groupOptionsShow = ref(false)

            const trainingTime = ref({
                start: '00:00',
                end: '00:00'
            })

            const changeTrainer = (e) => {
                trainerId.value = e.target.value
                trainerLabel.value = e.target.options[e.target.selectedIndex].text
            }

            const sendData = async () => {
                if (additionalFiles.value.length > 0) {
                    for (let i = 0; i < additionalFiles.value.length; i++) {
                        const compressedImage = await compressImageToBlobAsync(additionalFiles.value[i], 1000, 1000, 80)
                        additionalFiles.value[i] = compressedImage
                    }
                }
                console.log('additionalFiles', additionalFiles.value)
                trainingType.value = trainingType.value == 'none' ? null : trainingType.value
                if (!addCurrentDateFlag.value) {
                    loadingSpinnerFlag.value = true
                    addNewTraining(
                        group.value.name, 
                        group.value.group_id, 
                        location.value.small_name, 
                        location.value.location_id, 
                        trainingTime.value.start, 
                        trainingTime.value.end, 
                        changeDate.value,
                        trainingType.value,
                        description.value,
                        trainerId.value,
                        trainerLabel.value,
                        additionalFiles.value
                    ).then(res => {
                        console.log('result adding', res)
                        loadingSpinnerFlag.value = false
                        emit('updateTrainings')
                        emit('close')
                    })
                } else {
                    loadingSpinnerFlag.value = true
                    const days = []
                    trainingRepeat.value.forEach(day => {
                        if(day.selected) {
                            const allCurrentDayInMonth = getDatesByDayOfWeek(settedMonth, settedYear, day.value)
                            days.push(...allCurrentDayInMonth)
                        }
                    })
                    for (let i = 0; i < days.length; i++) {
                        await addNewTraining(
                            group.value.name, 
                            group.value.group_id, 
                            location.value.small_name, 
                            location.value.location_id, 
                            trainingTime.value.start, 
                            trainingTime.value.end, 
                            days[i],
                            trainingType.value,
                            description.value,
                            trainerId.value,
                            trainerLabel.value,
                            additionalFiles.value
                        )
                    }
                    loadingSpinnerFlag.value = false
                    emit('updateTrainings')
                    emit('close')
                }
            }

            const getDatesByDayOfWeek = (month, year, dayOfWeek) => {
                const dates = [];
                const date = new Date(year, month - 1, 1);
                const daysInMonth = new Date(year, month, 0).getDate();
                
                let firstDayOfWeek = date.getDay();
                if (firstDayOfWeek > dayOfWeek) {
                    firstDayOfWeek = dayOfWeek - firstDayOfWeek + 7;
                } else {
                    firstDayOfWeek = dayOfWeek - firstDayOfWeek;
                }
                
                date.setDate(firstDayOfWeek + 1);
                dates.push(new Date(date));
                
                while (date.getDate() + 7 <= daysInMonth) {
                    date.setDate(date.getDate() + 7);
                    dates.push(new Date(date));
                }
                
                console.log('dates', dates)
                return dates;
            }

            return {
                changeDate,
                trainingTime,
                group,
                location,
                groupOptions,
                locationOptions,
                locationOptionsShow,
                groupOptionsShow,
                groupVisibleOptions,
                locationVisibleOptions,
                groupText,
                locationText,
                changeGroupText,
                changeLocationText,
                setLocation,
                setGroup,
                sendData,
                trainingRepeat,
                addCurrentDateFlag,
                loadingSpinnerFlag,
                trainingType,
                trainerId,
                trainerLabel,
                allTrainers,
                changeTrainer,
                additionalFiles,
                uploadAdditionalPhoto,
                fileInput,
                showImageViewer,
                currentImage,
                description
            }
        },

        components: {
            MultiSelect,
            FileView,
            ImageViewer
        }
    }
</script>

<style lang="scss" scoped>
    @font-face {
        font-family: 'Fixel';
        src: url('../../../assets/fonts/FixelDisplay/FixelDisplay-Regular.ttf');
    }

    .window {
        font-family: 'Fixel';
    }
</style>