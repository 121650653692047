<template>
    <div style="background-color: rgba(29, 29, 29, .65);" class="w-[100%]  ml-[-10px] top-0 h-full absolute z-20"></div>
    <div v-if="loadingSpinnerFlag" class="z-[150] fixed w-full h-full top-[50%] left-[50%] ml-[115px]">
        <div class="w-10 h-10 rounded-full animate-spin border-solid border-4 border-[#F9245A] border-t-transparent "></div>
    </div>
    <div v-else class="bg-[#2B2A29] shadow-md  w-[254px] absolute top-[50%] left-[50%] ml-[130px] translate-x-[-50%] translate-y-[-50%] z-50 px-[17px] py-[17px] rounded-[10px] select-none border-[1px] border-[#47484966]">
        <div>
            <div class="border-b-[1px] border-[#4EAE3B] pb-[5px] text-center text-[#4EAE3B]">
                присутні
            </div>
            <div class="mt-2 h-[210px] overflow-y-auto scrolltab">
                <div v-for="student in attendedStudents" class="text-[#4EAE3B]">{{ student.name + ' ' + student.surname }}</div>
            </div>
        </div>
        <div class="mt-3">
            <div class="border-b-[1px] border-[#F9245A] pb-[5px] text-center text-[#F9245A]">
                відсутні
            </div>
            <div class="mt-2 h-[210px] overflow-y-auto scrolltab">
                <div v-for="student in missedStudents" class="text-[#F9245A]">{{ student.name + ' ' + student.surname }}</div>
            </div>
        </div>
        <div class="mt-2">
            <button @click="$emit('close')" class="bg-[#F9245A] text-[#1D1D1D] text-center w-full rounded-md py-1 hover:bg-opacity-90">Закрити</button>
        </div>
    </div>
</template>

<script>
    import { ref, onMounted } from 'vue'
    import { getStudentsWhichAttendedTraining, getStudentsWhichMissedTraining, getAllStudentsListInGroup } from '@/services/apiRequests'

    export default {
        name: 'training-attending-view', 

        props: {
            trainingDate: {
                type: Date,
                required: true
            },
            startTime: {
                type: String,
                required: true
            },
            groupId: {
                type: String,
                required: true
            }
        },

        setup(props, { emit }) {
            const attendedStudents = ref([])
            const missedStudents = ref([])
            const loadingSpinnerFlag = ref(true)

            onMounted(async () => {
                let attendedRes = await getStudentsWhichAttendedTraining(props.trainingDate, props.groupId)
                if (attendedRes.data.length == 0) {
                    attendedRes = await getStudentsWhichAttendedTraining(props.trainingDate.split('T')[0] + 'T' + props.startTime + ':00', props.groupId)
                }
                let missedRes = await getStudentsWhichMissedTraining(props.trainingDate, props.groupId)
                if (missedRes.data.length == 0) {
                    missedRes = await getStudentsWhichMissedTraining(props.trainingDate.split('T')[0] + 'T' + props.startTime + ':00', props.groupId)
                }

                const playersInGroup = await getAllStudentsListInGroup(props.groupId)
                console.log('playersInGroup ---------- ', playersInGroup)
                console.log('trainingDate ---------- ', props.trainingDate)

                attendedStudents.value = attendedRes.data.filter(student => student.group_id == props.groupId)
                missedStudents.value = missedRes.data.filter(student => student.group_id == props.groupId)

                console.log('attendedStudents.value ------- ', attendedRes)
                console.log('missedStudents.value ------- ', missedRes)
                loadingSpinnerFlag.value = false
            })

            return {
                attendedStudents,
                missedStudents,
                loadingSpinnerFlag
            }
        }
    }
</script>

<style lang="scss" scoped>
    .scrolltab::-webkit-scrollbar {
        width: 3px;               /* ширина всей полосы прокрутки */
    }

    .scrolltab::-webkit-scrollbar-track {
        background: transparent;        /* цвет зоны отслеживания */
    }

    .scrolltab::-webkit-scrollbar-thumb {
        background-color: #a6a4a4;
        border-radius: 20px;       /* округлось бегунка */ /* отступ вокруг бегунка */
    }

</style>