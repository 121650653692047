<template>
    <div>
        <GetPaymentPopup 
            v-if="getPaymentFlag"
            @close="getPaymentFlag = false"
            @updateTransactions="updateTransactions"
        />
        <outlayFilterPopup 
            v-if="showPopup"
            :filters = "filtersModel"
            @close="showPopup = false"
            @setFilters="filters => setFilters(filters)"
            @clearFilters="clearFilters"
        />
        <ImageViewer 
            v-if="showPictureFlag"
            :imageUrl="showPictureUrl"
            :altText="showPictureUrl"
            @close="showPictureFlag = false"
        />
        <AddInvest 
            v-if="showAddInvestPopup"
            @close="showAddInvestPopup = false"
            @updateTransactions="updateTransactions"
        />
        <div class="mt-[10px] flex justify-between w-[90%] items-center mx-auto mb-[30px]">
            <div>
                <!-- <button @click="getPaymentFlag = true" class="bg-gray-500 px-2 align-middle rounded-md text-sm py-[2px] ml-[5px] hover:bg-opacity-90 text-white ">
                    Прийняти кошти
                </button>  
                <button @click="showAddInvestPopup = true" class="bg-gray-500 px-2 align-middle rounded-md text-sm py-[2px] ml-[5px] hover:bg-opacity-90 text-white ">
                    Додати інвестицію
                </button>   -->
                <!-- <button @click="" class="bg-gray-500 px-2 align-middle rounded-md text-sm py-[2px] ml-[5px] hover:bg-opacity-90 text-white ">
                    Кастомна транзакція
                </button>   -->
            </div>
            <div class="flex justify-end">
                <div>
                    <!-- <span class="text-[#FFFFFF]">Кількість транзакцій:</span>
                    <input v-model="countOfTransactions" type="number" class="w-[50px] outline-none ml-2 bg-transparent text-[#FFFFFF] rounded-md pl-[5px]"> -->

                    <div class="flex w-[110px] justify-between mr-[130px] text-[#FFFFFF]">
                        <img @click="prevPage" src="@/assets/icons/left_arrow.svg" class="cursor-pointer">
                        <div class="flex mx-[20px]">Сторінка: <input v-model="currentPage" type="number" class="bg-transparent mx-2 w-[5ch] outline-none px-1"> / <span class="ml-[10px]">{{ maxPage }}</span></div>
                        <img @click="nextPage" src="@/assets/icons/right_arrow.svg" class="cursor-pointer">
                    </div>
                </div>
                <div>
                    <button @click="showPopup = true" class="bg-gray-500 px-2 rounded-md text-sm py-[2px] ml-[5px] hover:bg-opacity-90 text-white ">
                        Фільтри
                    </button>    
                </div>
            </div>
        </div>

        <div class="w-[90%] mx-auto mt-3 mb-[20px] rounded-[10px] overflow-auto" :key="tableObjectKey">
            <table class="w-full">
                <thead class="select-none">
                    <tr class="text-white border-b-[1px] border-[#252526]">
                        <th class="px-3 py-2 text-start font-medium text-sm">Відправник</th>
                        <th class="px-3 py-2 text-start font-medium text-sm">Статус відправника</th>
                        <th class="px-3 py-2 text-start font-medium text-sm">Тип транзакції</th>
                        <th class="px-3 py-2 text-end font-medium text-sm">Сума</th>
                        <th class="px-3 py-2 text-center font-medium text-sm"></th>
                        <th class="px-3 py-2 text-start font-medium text-sm">Дата</th>
                        <th class="px-3 py-2 text-start font-medium text-sm">Час</th>
                        <th class="px-3 py-2 text-start font-medium text-sm"></th>
                    </tr>
                </thead>
                <tbody> 
                    <outlay-row 
                        v-for="outlay in showoutlays"
                        :outlay="outlay"
                        @addRecieverName="outlay.receiverName = $event"
                        @addRecieverStatus="outlay.receiverStatus = $event"
                        @showPicture="showPicture($event)"
                    />
                </tbody>
            </table>
        </div>

    </div>
</template>

<script>
    import { uuid } from 'vue3-uuid'
    import { useRouter, useRoute } from 'vue-router'
    import { ref, onMounted, watch, onUpdated } from 'vue'
    import { getCertainNumberOfOutlays, getOutlayTransactionById, getUniversalTransactionByBalanceId, getOutlayTransactionByBalanceId } from '@/services/apiRequests'
    import OutlayRow from './components/OutlayRow.vue'
    import OutlayFilterPopup from './components/OutlayFilterPopup.vue'
    import ImageViewer from './ui/ImageViewer.vue'

    export default {
        name: 'outlays', 

        setup() {
            const countOfTransactions = ref(10)
            const alloutlays = ref([])
            const showoutlays = ref([])
            const tableObjectKey = ref(uuid.v1())
            const showPopup = ref(false)
            const filtersModel = ref({
                receiver_type: 'all',
                transaction_type: 'all',
                amount_from: null,
                amount_to: null,
                date_from: null,
                date_to: null,
                current_balance_id: null,
                current_transaction_id: null
            })
            const router = useRouter()
            const route = useRoute()

            const showPictureFlag = ref(false)
            const showPictureUrl = ref({})

            const getPaymentFlag = ref(false)

            const currentPage = ref(1)
            const maxPage = ref(100)

            const showAddInvestPopup = ref(false)

            const clearFilters = async () => {
                filtersModel.value = {
                    receiver_type: 'all',
                    transaction_type: 'all',
                    amount_from: null,
                    amount_to: null,
                    date_from: null,
                    date_to: null,
                    current_balance_id: null,
                    current_transaction_id: null
                }
                showPopup.value = false

                const unmappedTransactions = (await getCertainNumberOfOutlays(20)).transactions
                alloutlays.value = mapTransactions(unmappedTransactions)
                showoutlays.value = alloutlays.value
                tableObjectKey.value = uuid.v1()
                
                router.push('/outlays')
            }

            const showPicture = (url) => {
                showPictureUrl.value = process.env.VUE_APP_API_URL + 'static/' + url
                showPictureFlag.value = true
            }

            const mapTransactions = (unmappedTransactions) => {
                return unmappedTransactions.map(outlay => {
                    const date = outlay.date.split('T')[0].split('-').reverse().join('.')
                    const time = outlay.date.split('T')[1].split('.')[0].split(':').slice(0, 2).join(':')
                    const transactionType = outlay.transactions_type.includes('virtual') ? 'Віртуальна' : 'Реальна'
                    const amount = outlay.amount
                    return {
                        receiverName: null,
                        receiverStatus: null,
                        receiverBalanceId: outlay.sender_balance_id,
                        id: outlay.tx_id,
                        label: outlay.label,
                        embedVirtualoutlayId: outlay.embed_virtual_outlay,
                        additional_files: outlay.additional_files,
                        date,
                        time,
                        transactionType,
                        amount,
                    }
                })
            }

            const prevPage = () => {
                currentPage.value > 1 ? currentPage.value-- : currentPage.value = 1
            }

            const nextPage = () => {
                currentPage.value += 1
            }

            const updateTransactions = async () => {
                if (route.params.tx_id || route.params.balance_id) {
                    await filterByTxOrBalanceIds()
                } else {
                    const unmappedTransactions = (await getCertainNumberOfOutlays(20)).transactions
                    alloutlays.value = mapTransactions(unmappedTransactions)
                    showoutlays.value = alloutlays.value
                }
            }

            watch(currentPage, async () => {
                const unmappedTransactions = (await getCertainNumberOfOutlays(20, 20 * (currentPage.value - 1))).transactions
                alloutlays.value = mapTransactions(unmappedTransactions)
                showoutlays.value = alloutlays.value
                tableObjectKey.value = uuid.v1()
            })

            onMounted(async () => {
                await updateTransactions()
            })

            const filterByTxOrBalanceIds = async () => {
                filtersModel.value.current_transaction_id = route.params.tx_id
                filtersModel.value.current_balance_id = route.params.balance_id

                if (route.params.tx_id) {
                    const unmappedTransactions = [(await getOutlayTransactionById(route.params.tx_id)).transaction, ]
                    alloutlays.value = mapTransactions(unmappedTransactions)
                    showoutlays.value = alloutlays.value
                    tableObjectKey.value = uuid.v1()
                } else if (route.params.balance_id) {
                    const unmappedTransactions = (await getOutlayTransactionByBalanceId(route.params.balance_id)).transactions
                    alloutlays.value = mapTransactions(unmappedTransactions)
                    showoutlays.value = alloutlays.value
                    tableObjectKey.value = uuid.v1()
                }
            }

            watch(route, async () => {
                console.log('a')
                if (route.params.tx_id || route.params.balance_id) {
                    await filterByTxOrBalanceIds()
                } else {
                    console.log('a')
                    const unmappedTransactions = (await getCertainNumberOfOutlays(20)).transactions
                    alloutlays.value = mapTransactions(unmappedTransactions)
                    showoutlays.value = alloutlays.value
                    tableObjectKey.value = uuid.v1()
                }
            })

            watch(countOfTransactions, async () => {
                const unmappedTransactions = (await getCertainNumberOfOutlays(20)).transactions
                alloutlays.value = mapTransactions(unmappedTransactions)
                showoutlays.value = alloutlays.value
                tableObjectKey.value = uuid.v1()
            })

            const setFilters = (filters) => {
                console.log(filters)
                filtersModel.value = filters
                showPopup.value = false
                let result = alloutlays.value
                
                result = result.filter(outlay => {
                    return outlay.receiverStatus === filters.receiver_type || filters.receiver_type === 'all'
                })
                result = result.filter(outlay => {
                    return outlay.transactionType === filters.transaction_type || filters.transaction_type === 'all'
                })
                

                if (filters.amount_from || filters.amount_to) {           
                    filters.amount_from = filters.amount_from ?? 0
                    filters.amount_to = filters.amount_to ?? Infinity
                    result = result.filter(outlay => {     
                        return outlay.amount >= filters.amount_from && outlay.amount <= filters.amount_to
                    })
                }

                if (filters.date_from || filters.date_to) {
                    filters.date_from = filters.date_from ?? '01.01.1970'
                    filters.date_to = filters.date_to ?? '01.01.3000'
                
                    result = result.filter(outlay => {
                        const outlayDate = new Date(outlay.date.split('.').reverse().join('-'))
                        const dateFrom = new Date(filters.date_from.split('.').reverse().join('-'))
                        const dateTo = new Date(filters.date_to.split('.').reverse().join('-'))

                        if (outlayDate >= dateFrom && outlayDate <= dateTo) {
                            return true
                        }
                    })
                
                }

                showoutlays.value = result
                tableObjectKey.value = uuid.v1()
            }

            return {
                countOfTransactions,
                alloutlays,
                tableObjectKey,
                showPopup,
                setFilters,
                showoutlays,
                filtersModel,
                router,
                clearFilters,
                getPaymentFlag,
                updateTransactions,
                showPicture,
                showPictureFlag,
                showPictureUrl,
                showAddInvestPopup,
                currentPage,
                maxPage,
                prevPage,
                nextPage
            }
        },

        components: {
            OutlayRow,
            OutlayFilterPopup,
            ImageViewer
        }
    }
</script>
