<template>
    <div class="bg-[#1D1D1D] rounded-[9px] w-[300px] ml-[100px] border-[1px] border-[#252526]">
        <div class="flex justify-center mt-[13px] mb-[10px]">
            <img src="@/assets/logo.svg" class="w-[56px]">
        </div>
        <div class="text-center px-[33px] text-[15px] text-white font-semibold">
            {{ alertText }}
        </div>
        <div class="text-center mt-[11px] px-[33px] block text-[13px] text-white font-normal">
            Ваші зміни будуть втрачені якщо ви не збережете їх.
        </div>
        <div class="flex flex-col justify-center items-center mt-[17px]">
            <button @click="$emit('doSave')" class="bg-[#4EAE3B] hover:bg-opacity-90 font-semibold rounded-[5px] text-center w-[228px] py-[4px] mb-[6px]">Зберегти</button>
            <button @click="$emit('dontSave')" class="bg-[#3B9BF4] hover:bg-opacity-90 font-semibold rounded-[5px] text-center w-[228px] py-[4px]">Не зберігати</button>
        </div>
        <div class="flex flex-col justify-center items-center mt-[16px] mb-[16px]">
            <button @click="$emit('returnToCurrent')" class="bg-[#3B9BF4] hover:bg-opacity-90 font-semibold rounded-[5px] text-center w-[228px] py-[4px]">Відмінити</button>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'exit-confirm-form',

        props: {
            alertText: {
                type: String,
                default: 'test'
            }
        }
    }
</script>
