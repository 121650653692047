<template>
    <!-- <div class="w-[100%] h-full bg-gray-300 opacity-30 absolute z-20"></div> -->
    <div style="background-color: rgba(29, 29, 29, .65);" class="w-[100%]  ml-[-10px] top-0 h-full absolute z-20"></div>

    <FileView 
        class="absolute z-[150] top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] ml-[130px]"
        :fileUrl="currentImage"
        @close="showImageViewer = false"
        @delete="additionalFiles.splice(additionalFiles.indexOf(currentImage), 1); showImageViewer = false"
        v-if="showImageViewer"
    />
    <TrainingAttendingView 
        v-if="showAttendingView"
        :trainingDate="training.date"
        :groupId="group.group_id"
        :startTime="training.start_time"
        @close="showAttendingView=false"
    />

    <div v-if="loadingSpinnerFlag" class="z-[150] fixed w-full h-full top-[50%] left-[50%] ml-[115px]">
        <div class="w-10 h-10 rounded-full animate-spin border-solid border-4 border-[#F9245A] border-t-transparent "></div>
    </div>
    <ExitConfirmForm 
        v-else-if="exitConfirmForm && !loadingSpinnerFlag"
        @doSave="sendData"
        @dontSave="$emit('close')"
        @returnToCurrent="exitConfirmForm = false"
        :alertText="'Бажаєте зберігти зміни в тренуванні?'"
        class="fixed top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] ml-[130px] z-50 border-[1px] border-[#252526] w-[254px]"
    />
    <div v-else-if="!exitConfirmForm && !loadingSpinnerFlag && !showAttendingView" class="bg-[#2B2A29] shadow-md  w-[254px] absolute top-[50%] left-[50%] ml-[130px] translate-x-[-50%] translate-y-[-50%] z-50 px-[17px] py-[17px] rounded-[10px] select-none border-[1px] border-[#47484966]">
        <!-- <div class="absolute top-[10px] right-[10px]">
            <button class="float-right relative" @click="$emit('close')">
                <img src="@/assets/icons/cross_icon.svg" class="w-3 h-3">
            </button>
        </div> -->
        <!-- <div class="font-semibold text-center text-[#ABD3EC]">
            Перегляд тренування
        </div> -->


        <div class="">
            <div>
                <div class="flex justify-center items-center">
                    <div class="border-[#8C8C8C] bg-[#474849] bg-opacity-20 border-[1px] w-full items-center flex justify-center relative rounded-[4px] px-[4px]">
                        <img v-if="training.type == 'training'" src="@/assets/icons/green_ball.svg" class="h-[18px] relative right-[2px]">
                        <img v-else-if="training.type == 'gym'" src="@/assets/icons/red_dumbbell.svg" class="h-[18px]">
                        <img v-else-if="training.type == 'competition'" src="@/assets/icons/gold_cup.svg" class="h-[18px]">
                        <img v-else src="@/assets/icons/green_ball.svg" class="h-[18px] relative right-[2px]">
                        <select v-model="trainingType" class="bg-transparent text-[#fff] outline-none text-center  placeholder-gray-500 py-[3px] w-full disabled:opacity-40" >
                            <option value="null" class="bg-[#1D1D1D]">Не задано</option>
                            <option value="training" class="bg-[#1D1D1D]">Тренування</option>
                            <option value="gym" class="bg-[#1D1D1D]">Спортзал</option>
                            <option value="competition" class="bg-[#1D1D1D]">Змагання</option>
                        </select>
                    </div>
                </div>
            </div>
        </div>

        <div class="mt-5">
            <div>
                <div class="flex justify-center items-center bg-[#474849] bg-opacity-20">
                    <input :disabled="addCurrentDateFlag" v-model="changeDate" class="custom-date-input bg-transparent text-[#fff] text-center outline-none border-[1px] border-[#8C8C8C] bg-[#474849] bg-opacity-20 placeholder-gray-500 rounded-[4px] py-[1px] px-[3px] w-full disabled:opacity-40" type="date">
                </div>
            </div>
        </div>

        <div class="mt-5">
            <div class="flex justify-center items-center">
                <div class="bg-[#474849] bg-opacity-20">
                    <input v-model="trainingTime.start" class="custom-time-input bg-transparent text-[#fff] outline-none border-[1px] border-[#8C8C8C] bg-[#474849] bg-opacity-20 placeholder-gray-500 rounded-[4px] py-[2px] px-1 w-full disabled:opacity-40" type="time">
                </div>
                <span class="font-bold mx-2 text-[#ABD3EC]">></span>
                <div class="bg-[#474849] bg-opacity-20">
                    <input v-model="trainingTime.end" class="custom-time-input bg-transparent text-[#fff] outline-none border-[1px] border-[#8C8C8C] bg-[#474849] bg-opacity-20 placeholder-gray-500 rounded-[4px] py-[2px] px-1 w-full disabled:opacity-40" type="time">
                </div>
            </div>
        </div>

        <div class="mt-5">
            <div>
                <div class="flex justify-center items-center">
                    <div class="w-full">
                        <div class="bg-[#474849] bg-opacity-20">
                            <input
                                @change="changeGroupText($event.target.value)"
                                :value="groupText"
                                @focus="groupOptionsShow = true"
                                @blur="groupOptionsShow = false" 
                                class="bg-transparent text-[#fff] text-center outline-none border-[1px] border-[#8C8C8C] bg-[#474849] bg-opacity-20 placeholder-gray-500 rounded-[4px] py-[3px] w-full disabled:opacity-40" 
                                type="text"
                            />
                        </div>
                        <div v-if="groupOptionsShow" class="overflow-auto absolute max-h-[100px] w-[220px] z-[150] border-[1px] border-[#8C8C8C] bg-[#474849] bg-opacity-20">
                            <div v-for="groupOption in groupVisibleOptions" @mousedown="setGroup(groupOption)" class="w-full px-1 bg-[#1D1D1D] hover:bg-[#1D1D1Dd6] cursor-pointer text-[#FFF]">{{groupOption.name}}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="mt-5">
            <div>
                <div class="flex justify-center items-center">
                    <div class="w-full">
                        <div class="bg-[#474849] bg-opacity-20">
                            <input
                                @change="changeLocationText($event.target.value)"
                                :value="locationText"
                                @focus="locationOptionsShow = true"
                                @blur="locationOptionsShow = false" 
                                class="bg-transparent text-[#fff] outline-none border-[1px] border-[#8C8C8C] bg-[#474849] bg-opacity-20 placeholder-gray-500 text-center rounded-[4px] py-1 px-[3px] w-full disabled:opacity-40" 
                                type="text"
                            />
                        </div>
                        <div v-if="locationOptionsShow" class="overflow-auto absolute max-h-[100px] w-[220px] z-[150] bg-[#1D1D1D] border-[1px] border-[#8C8C8C]">
                            <div v-for="locationOption in locationVisibleOptions" @mousedown="setLocation(locationOption)" class="w-full px-1 bg-[#1D1D1D] hover:bg-[#1D1D1Dd6] cursor-pointer text-[#FFF]">{{locationOption.name}}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="mt-5">
            <div>
                <div class="flex justify-center items-center">
                    <div class="w-full flex flex-col items-center relative">
                        <div class="bg-[#474849] bg-opacity-20 w-full">
                            <textarea
                                v-model="description"
                                class="bg-transparent text-[#fff] text-[13px] outline-none border-[1px] border-[#8C8C8C] bg-[#474849] bg-opacity-20 placeholder-gray-500 rounded-[4px] p-1 w-full disabled:opacity-40" 
                                type="text"
                                rows="6"
                                placeholder="Коментар до тренування"
                            ></textarea>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="mt-2">
            <div>
                <div class="flex justify-center items-center">
                    <div class="border-[#8C8C8C] bg-[#474849] bg-opacity-20 border-[1px] w-full items-center flex justify-center relative rounded-[4px] px-[4px]">
                        <select :value="trainerId" @change="changeTrainer" class="bg-transparent text-[#fff] outline-none text-center  placeholder-gray-500 py-[3px] w-full disabled:opacity-40" >
                            <option value="" class="bg-[#1D1D1D]">Тренер не заданий</option>
                            <option v-for="trainer in allTrainers" :value="trainer.trainer_id" class="bg-[#1D1D1D]">{{ trainer.name + ' ' + trainer.surname }}</option>
                        </select>
                    </div>
                </div>
            </div>
        </div>

        <div class="mt-5 flex items-center justify-between">
            <div class="flex">
                <div v-if="additionalFiles.length == 0 && newAdditionalFiles.length == 0" class="text-white opacity-25">Файли незакріплені</div>
                <div v-for="file in additionalFiles" @click="showImageViewer = true; currentImage = file" class="cursor-pointer mr-[5px]">
                    <img src="@/assets/icons/picture_icon.svg" alt="">
                </div>
                <div v-for="file in newAdditionalFiles" @click="showNewImageViewer = true; newCurrentImage = file" class="cursor-pointer mr-[5px]">
                    <img src="@/assets/icons/picture_icon.svg" alt="">
                </div>
            </div>
            <div>
                <img src="@/assets/icons/upload_icon_light.svg" class="cursor-pointer" @click="uploadAdditionalPhoto">
                <input 
                    ref="fileInput"
                    type="file" 
                    class="hidden"
                    @change="newAdditionalFiles.push($event.target.files[0])"
                />
            </div>
        </div>

        <div class="mt-5">
            <div v-if="training.status == 'completed'">
                <button @click="showAttendingView = true" class="bg-[#686868] text-[#fff] text-center w-full rounded-md py-1 hover:bg-opacity-90">Переглянути відвідування</button>
            </div>
            <div class="mt-2">
                <button @click="openUrl(location.geo_url)" class="bg-[#4EAE3B] text-[#fff] text-center w-full rounded-md py-1 hover:bg-opacity-90">Відкрити на карті</button>
            </div>
            <div class="mt-2">
                <button @click="proccessExit" class="bg-[#3B9BF4] text-[#1D1D1D] text-center w-full rounded-md py-1 hover:bg-opacity-90">Закрити</button>
            </div>
        </div>
    </div>
</template>

<script>
    import { ref, computed, reactive, onBeforeMount } from 'vue'
    import { getAllLocations, getAllGroups, updateTraining, getGroupById, getLocationById, getAllTrainers } from '@/services/apiRequests'
    import ExitConfirmForm from '../ui/ExitConfirmForm.vue'
    import FileView from '../ui/FileView.vue'
    import TrainingAttendingView from './TrainingAttendingView.vue'

    async function compressImageToBlobAsync(file, maxWidth, maxHeight, quality) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = function () {
            const img = new Image();
            img.src = reader.result;
            img.onload = function () {
                let width = img.width;
                let height = img.height;

                if (width > maxWidth) {
                height *= maxWidth / width;
                width = maxWidth;
                }

                if (height > maxHeight) {
                width *= maxHeight / height;
                height = maxHeight;
                }

                const canvas = document.createElement('canvas');
                const ctx = canvas.getContext('2d');
                canvas.width = width;
                canvas.height = height;

                ctx.drawImage(img, 0, 0, width, height);

                // Получаем Blob сжатого изображения
                canvas.toBlob(
                function (blob) {
                    if (blob) {
                    // Создаем новый объект File на основе Blob
                    const compressedFile = new File([blob], file.name, { type: 'image/jpeg' });
                    resolve(compressedFile);
                    } else {
                    reject(new Error('Ошибка при создании Blob'));
                    }
                },
                'image/jpeg',
                quality / 100
                );
            };

            img.onerror = function () {
                reject(new Error('Ошибка при загрузке изображения'));
            };
            };

            reader.onerror = function () {
            reject(new Error('Ошибка при чтении файла'));
            };

            reader.readAsDataURL(file);
        });
    }

    export default {
        name: 'full-training-view',

        props: {
            training: {
                type: Object,
                required: true
            }
        },

        setup(props, { emit }) {
            const settedDate = new Date(props.training.date)
            const settedYear = settedDate.getFullYear() > 10 ? settedDate.getFullYear() : `0${settedDate.getFullYear()}`
            const settedMonth = settedDate.getMonth() + 1 > 10 ? settedDate.getMonth() + 1 : `0${settedDate.getMonth() + 1}`
            const settedDay = settedDate.getDate() > 10 ? settedDate.getDate() : `0${settedDate.getDate()}`
            const changeDate = ref(`${settedYear}-${settedMonth}-${settedDay}`)

            const exitConfirmForm = ref(false)

            const group = ref(null)
            const groupText = ref(props.training.group_label)
            
            const location = ref(null)
            const locationText = ref(props.training.location_label)

            const groupOptions = ref([])
            const locationOptions = ref([])

            const trainingType = ref('none')
            
            const trainerId = ref('')
            const trainerLabel = ref('')
            const allTrainers = ref([])

            const additionalFiles = ref([])


            const fileInput = ref(null)
            const newAdditionalFiles = ref([])

            const showImageViewer = ref(false)
            const showNewImageViewer = ref(false)

            const currentImage = ref(null)
            const newCurrentImage = ref(null)

            const description = ref('')

            const loadingSpinnerFlag = ref(false)

            const bufferedTraining = reactive({
                group: null,
                location: null,
                trainingTime: {
                    start: null,
                    end: null
                },
                changeDate: null,
                trainingType: null,
                description: null,
                trainerId: null,
                trainerLabel: null,
                additionalFilesLength: null
            })

            const showAttendingView = ref(false)

            const openUrl = (url) => {
                window.open(url, '_blank')
            }

            onBeforeMount(async () => {
                loadingSpinnerFlag.value = true
                const resGroups = await getAllGroups()
                groupOptions.value = resGroups.data

                const resLocations = await getAllLocations()
                locationOptions.value = resLocations.data

                const currentGroupRes = await getGroupById(props.training.group_id)
                group.value = currentGroupRes.data

                const currentLocationRes = await getLocationById(props.training.location_id)
                location.value = currentLocationRes.data

                const resTrainers = await getAllTrainers()
                allTrainers.value = resTrainers.data

                const currentTrainer = allTrainers.value.find(trainer => trainer.trainer_id === props.training.trainer_id)
                trainerId.value = currentTrainer.trainer_id
                trainerLabel.value = currentTrainer.name + ' ' + currentTrainer.surname

                additionalFiles.value = props.training.additional_files ? props.training.additional_files : []

                trainingType.value = props.training.type
                description.value = props.training.description

                bufferedTraining.group = group.value
                bufferedTraining.location = location.value
                bufferedTraining.trainingTime.start = props.training.start_time
                bufferedTraining.trainingTime.end = props.training.end_time
                bufferedTraining.changeDate = changeDate.value
                bufferedTraining.trainingType = trainingType.value
                bufferedTraining.description = description.value
                bufferedTraining.trainerId = trainerId.value
                bufferedTraining.trainerLabel = trainerLabel.value
                console.log('additionalFiles.value -------- ', additionalFiles.value)
                if (additionalFiles.value) {
                    bufferedTraining.additionalFilesLength = additionalFiles.value.length
                } else {
                    bufferedTraining.additionalFilesLength = 0
                }

                loadingSpinnerFlag.value = false
            })

            const proccessExit = () => {
                if (bufferedTraining.group != group.value || bufferedTraining.location != location.value || bufferedTraining.trainingTime.start != trainingTime.value.start || bufferedTraining.trainingTime.end != trainingTime.value.end || bufferedTraining.changeDate != changeDate.value || bufferedTraining.trainingType != trainingType.value || bufferedTraining.description != description.value || bufferedTraining.trainerId != trainerId.value || bufferedTraining.trainerLabel != trainerLabel.value || bufferedTraining.additionalFilesLength != additionalFiles.value.length || newAdditionalFiles.value.length > 0) {
                    exitConfirmForm.value = true
                } else {
                    emit('close')
                }
            }

            const groupVisibleOptions = computed(() => {
                return groupOptions.value.filter(option => option.name.toLowerCase().includes(groupText.value.toLowerCase()) || groupText.value === '')
            })

            const locationVisibleOptions = computed(() => {
                return locationOptions.value.filter(option => option.name.toLowerCase().includes(locationText.value.toLowerCase()) || locationText.value === '')
            })

            const changeTrainer = (e) => {
                trainerId.value = e.target.value
                trainerLabel.value = e.target.options[e.target.selectedIndex].text
            }

            const changeGroupText = (groupName) => {
                groupText.value = groupName
                group.value = groupVisibleOptions.value[0]
            }

            const changeLocationText = (locationName) => {
                locationText.value = locationName
                location.value = locationVisibleOptions.value[0]
            }

            const setLocation = (locationOption) => {
                location.value = locationOption
                locationText.value = locationOption.name
                locationOptionsShow.value = false
            }

            const setGroup = (groupOption) => {
                group.value = groupOption;
                groupText.value = groupOption.name;
                groupOptionsShow.value = false;
            }

            const locationOptionsShow = ref(false)
            const groupOptionsShow = ref(false)

            const trainingTime = ref({
                start: props.training.start_time,
                end: props.training.end_time
            })

            const uploadAdditionalPhoto = () => {
                fileInput.value.click()
            }

            const sendData = async () => {
                loadingSpinnerFlag.value = true
                const newAdditionalFilesArray = []
                for (let i = 0; i < newAdditionalFiles.value.length; i++) {
                    const compressedFile = await compressImageToBlobAsync(newAdditionalFiles.value[i], 600, 600, 90)
                    newAdditionalFilesArray.push(compressedFile)
                }
                await updateTraining(
                    props.training.training_id,
                    group.value.name, 
                    group.value.group_id, 
                    location.value.small_name, 
                    location.value.location_id, 
                    trainingTime.value.start, 
                    trainingTime.value.end, 
                    changeDate.value,
                    trainingType.value,
                    description.value,
                    trainerId.value,
                    trainerLabel.value,
                    additionalFiles.value,
                    newAdditionalFilesArray
                )
                emit('updateTrainings')
                emit('close')
                loadingSpinnerFlag.value = false
            }            

            return {
                changeDate,
                trainingTime,
                group,
                location,
                groupOptions,
                locationOptions,
                locationOptionsShow,
                groupOptionsShow,
                groupVisibleOptions,
                locationVisibleOptions,
                groupText,
                locationText,
                changeGroupText,
                changeLocationText,
                setLocation,
                setGroup,
                sendData,
                trainingType,
                description,
                exitConfirmForm,
                loadingSpinnerFlag,
                allTrainers,
                trainerId,
                trainerLabel,
                additionalFiles,
                fileInput,
                showImageViewer,
                currentImage,
                newAdditionalFiles,
                showNewImageViewer,
                newCurrentImage,
                uploadAdditionalPhoto,
                changeTrainer,
                proccessExit,
                openUrl,
                showAttendingView
            }
        },

        components: {
            ExitConfirmForm,
            FileView,
            TrainingAttendingView
        }
    }
</script>

<style lang="scss">

/* Скрываем стандартную стрелку в поле ввода */
.custom-date-input::-webkit-calendar-picker-indicator {
    background-image: url('../../../assets/icons/white_calendar.svg');
    cursor: pointer;
}

.custom-time-input::-webkit-calendar-picker-indicator {
    background-image: url('../../../assets/icons/clock_icon.svg');
    cursor: pointer;
}

</style>
