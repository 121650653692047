<template>
    <div class="flex justify-start">
      <canvas ref="canvas" :height="height" :width="864"></canvas>
    </div>
  </template>
  
  <script>
  export default {
    name: 'BarChart',
  
    props: {
      data: {
        type: Array,
        required: true
      },
      bottomBarColor: {
        type: String,
        default: '#00bfff'
      },
      topBarColor: {
        type: String,
        default: '#00bfff'
      },
      lineColor: {
        type: String,
        default: '#d3d3d3'
      },
      labels: {
        type: Array,
        required: true
      },
      height: {
        type: Number,
        default: 230
      },
      width: {
        type: Number,
        default: 864
      }
    },
  
    mounted() {
      this.drawChart();
    },
  
    methods: {
      drawChart() {
        const canvas = this.$refs.canvas;
        const ctx = canvas.getContext('2d');
        const height = canvas.height;
        const width = canvas.width;
        const max = Math.max(...this.data.map(bar => bar[1] + bar[0]));
        const barWidth = width / (this.data.length * 2) > 36 ? 36 : width / (this.data.length * 2);
        const spacing = 1;
        const marginBottom = 25;
        const marginTop = 45;
        const barMaxHeight = height - (marginBottom + marginTop);

        ctx.beginPath();
        this.data.forEach((bar, i) => {

            const fullBarValue = bar[0] + bar[1];
            const currentBarPercentFromFull = (100 * fullBarValue) / max;

            const currentBlockFullHeight = (barMaxHeight * currentBarPercentFromFull) / 100;

            const topBlockHeight = (barMaxHeight * (bar[0] * 100) / max) / 100;
            const bottomBlockHeight = (barMaxHeight * (bar[1] * 100) / max) / 100;

            ctx.fillStyle = this.topBarColor;
            ctx.fillRect(
                (i * barWidth * 2) + spacing,
                height - currentBlockFullHeight - marginBottom,
                barWidth,
                bottomBlockHeight
            );

            ctx.fillStyle = this.bottomBarColor;
            ctx.fillRect(
                (i * barWidth * 2) + spacing,
                height - currentBlockFullHeight + bottomBlockHeight - marginBottom,
                barWidth,
                topBlockHeight
            );

            const group = this.labels[i]
            ctx.fillStyle = '#fff';
            ctx.font = '12px Arial';
            ctx.textAlign = 'center';
            const labelX = ((i * barWidth * 2) + spacing) + (barWidth / 2);
            const labelY = height + 10;
            const labelText = group;
            ctx.fillText(labelText, labelX, labelY - marginBottom / 2);

            ctx.strokeStyle = this.lineColor;
            ctx.lineWidth = 6;
            if (i === 0) {
              ctx.moveTo(((i * barWidth * 2) + spacing) + (barWidth / 2), height - currentBlockFullHeight - marginBottom);
            } else {
              ctx.lineTo(((i * barWidth * 2) + spacing) + (barWidth / 2), height - currentBlockFullHeight - marginBottom);
            }
            
        });
        ctx.stroke();

        ctx.beginPath();
        this.data.forEach((bar, i) => {
            const fullBarValue = bar[0] + bar[1];
            const currentBarPercentFromFull = (100 * fullBarValue) / max;

            const currentBlockFullHeight = (barMaxHeight * currentBarPercentFromFull) / 100;

            ctx.moveTo(((i * barWidth * 2) + spacing) + (barWidth / 2), height - currentBlockFullHeight - marginBottom);
            ctx.arc(((i * barWidth * 2) + spacing) + (barWidth / 2), height - currentBlockFullHeight - marginBottom, 3, 0, 2 * Math.PI);
            ctx.strokeStyle = this.lineColor;
            ctx.lineWidth = 4;
            ctx.stroke();

            ctx.fillStyle = '#12214C';
            ctx.fill();
            
            ctx.strokeStyle = '#12214C';
            ctx.lineWidth = 1;

            const x = ((i * barWidth * 2) + spacing) + (barWidth / 2);
            const y = height - currentBlockFullHeight - marginBottom;
            const dataPoint = bar[0] + bar[1];

            ctx.fillStyle = this.lineColor;
            ctx.font = 'bold 12px Arial';
            ctx.textAlign = 'center';
            const labelX = x;
            const labelY = y - 10;
            const labelText = dataPoint.toLocaleString();
            ctx.fillText(labelText, labelX, labelY);
        });
        ctx.stroke();
      }
    }
  };
  </script>
  
  <style scoped>
  .chart-container {
    width: 100%;
    height: 100%;
  }
  </style>
  