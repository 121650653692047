<template>
    <tr class="border-b-[1px] border-[#252526]">
        <td class="px-3 py-2 text-start text-sm w-[20.86%] text-[#FFFFFF]">
            <span v-if="outlay.receiverName">{{ outlay.receiverName }}</span>
            <Skeletor v-else class="rounded-[10px]" width="70" height="15" pill as="div" :shimmer="true"/>
        </td>
        <td class="px-3 py-2 text-start text-sm w-[22.43%] text-[#FFFFFF]">
            <span v-if="outlay.receiverName">{{ outlay.receiverStatus }}</span>
            <Skeletor v-else class="rounded-[10px]" width="70" height="15" pill as="div" :shimmer="true"/>
        </td>
        <td class="px-3 py-2 text-start text-sm w-[10.88%] text-[#FFFFFF]">{{ outlay.transactionType }}</td>
        <td class="px-3 py-2 text-end text-sm w-[10.00%] text-red-500">{{ outlay.amount.toFixed(2).toLocaleString() }}</td>
        <td class="px-3 py-2 text-center text-sm w-[5.68%] text-[#FFFFFF]"></td>
        <td class="px-3 py-2 text-start text-sm w-[13.67%] text-[#FFFFFF]">{{ outlay.date }}</td>
        <td class="px-3 py-2 text-start text-sm w-[8.65%] text-[#FFFFFF]">{{ outlay.time }}</td>
        <td class="px-3 py-2 text-start text-sm  flex justify-end">
            <img src="@/assets/icons/invoice_icon.svg" class="cursor-pointer mr-[10px] " @click="fullView = !fullView">
        </td>
    </tr>
    <tr v-if="fullView">
        <td colspan="3" class="w-full text-white">
            <div class="px-3 my-[20px]">
                <div class="text-sm">
                    Назва балансу: <span class="font-semibold">{{ balanceName }}</span>
                </div>
                <div class="text-sm mt-[10px]">
                    ID балансу: <router-link :to="'/outlays/balance_id/' + balanceId" class="text-blue-400 hover:underline">{{ balanceId }}</router-link>
                </div>
                <div class="text-sm mt-[20px]">
                    Коментар до транзакції: <i>«{{ outlay.label }}»</i>
                </div>
                <div class="text-sm mt-[10px]">
                    ID транзакции: <router-link :to="'/outlays/tx_id/' + outlay.id" class="text-blue-400 hover:underline">{{ outlay.id }}</router-link>
                </div>
            </div>
        </td>
        <td colspan="5" class="w-full text-white">
            <div 
                class="w-full relative"
                :class="{
                    'mt-[-62px]' : outlay.additional_files?.length <= 0
                }"
                v-if="embedTxFlag"
            >
                <table class="w-full">
                    <thead>
                        <tr>
                            <th colspan="5" class="font-semibold text-start">Пов'язана транзакція</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="text-sm">
                            <td class="w-[20%]">{{ embedTxView.receiverName }}</td>
                            <td class="w-[20%]">{{ embedTxView.amount.toFixed(2) }}</td>
                            <td class="w-[20%]">{{ embedTxView.date }}</td>
                            <td class="w-[20%]">{{ embedTxView.time }}</td>
                            <td class="w-[20%]">
                                <button @click="$router.push('/outlays/tx_id/' + embedTxView.id)" class="bg-gray-500 text-[12px] text-white px-1 py-[2px] rounded-md">Переглянути</button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div 
                v-if="outlay.additional_files?.length > 0" 
                class="w-full mt-[15px]"
                :class="{
                    'mt-[-64px]' : !embedTxFlag
                }"    
            >
                <div class="w-full relative">
                    <div class="font-semibold">
                        Прікріплені файли
                    </div>
                    <div class="mt-[5px]">
                        <div v-for="file in outlay.additional_files" @click="$emit('showPicture', file)" class="w-[70px] text-[14px] text-center bg-gray-600 hover:bg-opacity-80 rounded-lg cursor-pointer text-white">Файл 1</div>
                    </div>
                </div>
            </div>
        </td>
    </tr>
    <tr v-if="fullView">
        <td colspan="8">
            <div class="w-full h-px border-b-[0.6px] border-gray-100"></div>
        </td>
    </tr>
</template>

<script>
    import { getBalanceObject, getVirtualoutlayById, getRealParentTransactionByVirtualTransactionId } from '@/services/apiRequests'
    import { ref, onMounted, watch, computed, onUpdated } from 'vue'
    import { Skeletor } from 'vue-skeletor'

    export default {
        name: 'outlay-row',

        props: {
            outlay: {
                type: Object,
                required: true
            }
        },

        emits: ['addRecieverName', 'addRecieverStatus'],

        setup(props, { emit }) {
            const fullView = ref(false)
            const balanceName = ref('')
            const balanceId = ref('')

            const updateData = async () => {
                const balanceObject = (await getBalanceObject(props.outlay.receiverBalanceId)).balance
                
                const receiverName = balanceObject.label.split(' ').reverse().slice(0, 2).reverse().map(item => item.charAt(0).toUpperCase() + item.slice(1)).join(' ')
                const receiverStatus = balanceObject.label.includes('учня') ? 'Учень' : balanceObject.label.includes('тренера') ? 'Тренер' : 'Сервіс' 
                balanceName.value = balanceObject.label
                balanceId.value = balanceObject.balance_id
                
                emit('addRecieverName', receiverName)
                emit('addRecieverStatus', receiverStatus)
            }

            onMounted(async () => {
                await updateData()
            })

            onUpdated(async () => {
                await updateData()
            })

            return {
                fullView,
                balanceName,
                balanceId
            }
        },

        components: {
            Skeletor
        }
    }
</script>

<style lang="scss" scoped>

    .vue-skeletor {
        background-color: #ccc;
        animation: shimmer 1.5s infinite;
        animation-duration: 2s; 
        animation-timing-function: ease-in-out;
    }

    /* Default keyframes used in skeletor */
    @keyframes shimmer {
        0% {
            opacity: .5;
        }
        50% {
            opacity: 1;
        }
        100% {
            opacity: .5;
        }
    }

</style>