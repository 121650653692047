<template>
    <tr class="border-b-[1px] border-[#252526]">
        <td @click="$emit('openLocationCard')" class="pl-3 pr-2 w-[60px] py-2 text-start text-sm cursor-pointer">
            <img v-if="location.photo" :src="apiURL + 'static/' + location.photo" class="w-10 h-10 rounded-full">
            <div v-else class="h-full w-full flex justify-center"><img src="@/assets/icons/picture_icon.svg" alt=""></div>
        </td>
        <td @click="$emit('openLocationCard')" class="px-3 py-2 text-start text-sm cursor-pointer">
            <!-- {{ location.name.length > 20 ? location.name.slice(0, 21) + '...' : location.name }} -->
            <div class="flex flex-col">
                <div class="truncate text-[#FFFFFF]">{{ location.name }}</div>
                <div class="truncate text-sm text-gray-400">{{ location.address.length > 30 ? location.address.slice(0, 31) + '...' : location.address }}</div>
            </div>
        </td>
        <td class="px-3 py-2 text-start text-sm truncate text-[#FFFFFF]">{{ location.small_name }}</td>
        <td class="px-3 py-2 text-start text-sm truncate text-[#FFFFFF]">{{ location.description.length > 40 ? location.description.slice(0, 41) + '...' : location.description }}</td>
        <td class="px-3 py-2 text-end text-sm">
            <button class="px-2 py-1 rounded-[10px] text-sm font-medium">
                <img @click="$emit('openLocationCard')" src="@/assets/icons/pen_icon.svg" class="w-6 h-6">
            </button>
            <button class="px-2 py-1 rounded-[10px] text-sm font-medium text-red-600">
                <img @click="$emit('delete')" src="@/assets/icons/trash_icon.svg" class="w-6 h-6 ">
            </button>
        </td>
    </tr>
</template>

<script>
    import { ref, onBeforeMount, onUpdated } from 'vue'

    export default {
        props: {
            location: {
                type: Object,
                required: true
            }
        },
        
        setup(props) {
            const apiURL = ref(process.env.VUE_APP_API_URL)

            return {
                apiURL
            }
        }
    }
</script>
