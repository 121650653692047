<template>
    <div class="absolute shadow-[0_35px_60px_-15px_rgba(0,0,0,0.3)] bg-[#1D1D1D] top-[50%] left-[50%] p-4 rounded-[10px] mt-[-20px] w-[250px]">
        <div class="text-center mb-3 text-[#fff]">Ви дійсно хочете видалити тренера {{ trainer.name + ' ' + trainer.surname }}?</div>
        <div class="flex justify-between">
            <button @click="deleteTrainer" class="bg-[#F9245A] hover:bg-opacity-90 text-white px-4 py-1 rounded-[10px]">Так</button>
            <button @click="$emit('close')" class="bg-[#3B9BF4] px-4 py-1 rounded-[10px]">Ні</button>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'delete-student-form',

        props: {
            trainer: {
                type: Object,
                required: true
            }
        },

        setup(props, { emit }) {
            const deleteTrainer = () => {
                emit('acceptDeleteTrainer', props.trainer)
            }

            return {
                deleteTrainer
            }
        }
    }
</script>
