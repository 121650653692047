<template>
    <tr class="w-full text-[#fff]">
        <td class="flex justify-center items-center">
            <div class="w-[50px] h-[50px] mt-[10px] flex items-center rounded-full overflow-hidden border-[#ABD3EC] border-[1px]">
                <img v-if="photo" :src="apiURL + 'static/' + photo">
                <img v-else src="@/assets/student-photo-placeholder-tpbg.png" class="w-[37px] relative top-[2px] mx-auto">
            </div>
        </td>
        <td class="text-start pl-[5px] align-middle w-[15%] text-[15px]">{{ name }}</td>
        <td class="text-center align-middle font-semibold w-[7%] text-[15px]">{{ groups }}</td>
        <td class="text-center align-middle font-semibold w-[7%] text-[15px]">{{ students }}</td>
        <td class="w-[30%]">
            <MinLineChart class="mx-auto relative right-[12px]" :data="completed_trainings_count"/>
        </td>
        <td class="w-[30%]">
            <MinBarChart class="relative left-[10px]" :data="trainer_groups_incomes" :lables="['Січ', 'Лют', 'Бер', 'Квіт', 'Трав', 'Черв', 'Лип', 'Серп', 'Вер', 'Жовт', 'Лист', 'Груд']"/>
        </td>
    </tr>
</template>

<script>
    import { ref, computed, watch } from 'vue'
    import MinLineChart from '../MinLineChart.vue'
    import MinBarChart from '../MinBarChart.vue'

    export default {
        name: 'TrainerRow',

        props: {
            name: {
                type: String,
                required: true
            },
            groups: {
                type: Number,
                required: true
            },
            students: {
                type: Number,
                required: true
            },
            photo: {
                type: String,
                required: true
            },
            completed_trainings_count: {
                type: Array,
                required: true
            },
            trainer_groups_incomes: {
                type: Array,
                required: true
            }
        },

        setup(props) {
            const apiURL = ref(process.env.VUE_APP_API_URL)

            return {
                apiURL
            }
        },

        components: {
            MinLineChart,
            MinBarChart
        },
    }
</script>

<style lang="scss" scoped>

</style>