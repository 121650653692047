<template>
    <div class="fixed shadow-[0_35px_60px_-15px_rgba(0,0,0,0.3)] top-[50%] left-[50%] bg-[#1D1D1D] p-4 rounded-[10px] mt-[-20px] w-[300px]">
        <div class="text-center mb-3 text-[#FFF]">
            Ви дійсно хочете видалити адміна {{ admin.admin_name + ' ' + admin.admin_surname }}?
            <br>
            <span class="text-[11px]">(його баланси залишаться в системі для майбутніх транзакцій)</span>
        </div>
        <div class="flex justify-between">
            <button @click="deleteAdmin" class="bg-[#F9245A] hover:bg-opacity-90 text-white px-4 py-1 rounded-[10px]">Так</button>
            <button @click="$emit('close')" class="px-4 py-1 rounded-[10px] bg-[#3B9BF4]">Ні</button>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'delete-admin-form',

        props: {
            admin: {
                type: Object,
                required: true
            }
        },

        setup(props, { emit }) {
            const deleteAdmin = () => {
                emit('acceptDeleteAdmin', props.admin)
            }

            return {
                deleteAdmin
            }
        }
    }
</script>
