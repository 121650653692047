<template>
    <div class="z-30 bg-[#2B2A29] rounded-[10px] w-[250px] h-[330px] absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] ml-[100px]">
        <div class="h-[285px] overflow-auto flex flex-col justify-end">

            <div v-for="trainer in visibleTrainers" @click="$emit('selectTrainer', trainer)" class="bg-[#2B2A29] flex px-3 py-2 cursor-pointer hover:bg-[#242322]">
                <div class="mr-2 w-[24px] h-[24px] rounded-full overflow-hidden border-[#4EAE3B] border-[1px]">
                    <img :src="apiURL + 'static/' + trainer.photo" @error="$event.target.src = require('@/assets/student-photo-placeholder-tpbg.png')" class="">
                </div>
                <div class="text-[#4EAE3B]">
                    {{ trainer.name + ' ' + trainer.surname}}
                </div>
            </div>
        </div>
        <div class="w-[90%] flex justify-between border-[#4EAE3B] border-[1px] text-[#4EAE3B] py-1 px-2 text-start text-[13px] mt-[10px] cursor-pointer select-none mx-auto rounded-md">
            <input v-model="currentTrainerName" type="text" placeholder="Ім'я тренера" class="text-[#4EAE3B] placeholder-[#4EAE3B6d] bg-transparent outline-none border-none">
            <img src="@/assets/icons/magnify_icon.svg" class="w-[10px]">
        </div>
    </div>
</template>

<script>
    import { ref, onBeforeMount, computed } from 'vue'
    import { getAllTrainers } from '@/services/apiRequests'

    export default {
        name: 'coach-selection-form',

        setup() {
            const trainers = ref([])
            const currentTrainerName = ref('')
            const apiURL = ref(process.env.VUE_APP_API_URL) 

            onBeforeMount(async () => {
                const response = await getAllTrainers()
                response.data.forEach(trainer => {
                    trainers.value.push({
                        trainer_id: trainer.trainer_id,
                        name: trainer.name,
                        surname: trainer.surname,
                        photo: trainer.photo
                    })
                })
            })

            const visibleTrainers = computed(() => {
                return trainers.value.filter(trainer => {
                    return trainer.name.toLowerCase().includes(currentTrainerName.value.toLowerCase()) || trainer.surname.toLowerCase().includes(currentTrainerName.value.toLowerCase())
                })
            }) 

            return {
                trainers,
                apiURL,
                currentTrainerName,
                visibleTrainers
            }
        }
    }
</script>
