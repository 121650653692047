<template>
    <div class="main-info bg-[#2B2A29] pt-2 h-[500px] w-[250px] flex flex-col mr-[24px] justify-center items-center rounded-[10px] border-[1px] border-[#47484966]">
        <div>
            <img 
                @click="uploadPhoto"
                src="@/assets/icons/upload_icon_light.svg" 
                class="absolute left-[210px] top-[20px] cursor-pointer z-40"
            >
            <input 
                ref="fileInput"
                type="file" 
                class="hidden"
                :value="inputValue"
                @change="$emit('loadPhoto', $event)"
            />
        </div>
        <img :src="photo" @error="photo = require('@/assets/student_photo_placeholder.svg')" class="h-[153px]">
        
        <div class="w-full bg-[#4EAE3B] pb-[6px] font-['Avenir Roman'] z-20">
            <div class="text-[#FFF] text-center text-3xl align-middle font-semibold mt-[7px] h-[34px] w-full bg-transparent border-none">
                <div class="h-[34px] align-middle" v-if="name?.length > 0 || surname?.length > 0">
                    <marquee v-if="name?.length + surname?.length + 1 > 13" behavior="scroll" loop="-1" direction="left">
                        <span v-for="id in 100" class="mx-3 pb-2">{{ name + ' ' + surname }}</span>
                    </marquee>
                    <span v-else>{{ name + ' ' + surname }}</span>
                </div>
                <span v-else class="opacity-25">Лєо Мессі</span>
            </div>
            <div class="flex justify-center h-[32px]">
                <div class="flex items-center mx-2">
                    <img src="@/assets/logo.svg" class="w-[20px]">
                    <div class="text-[#1D1D1D] mx-1 mb-[2px]">|</div>
                    <img src="@/assets/icons/uk_flag_dark.svg" class="w-[25px]">
                </div>
            </div>
        </div>
        <div class="w-full px-[13px] mt-[15px]">
            <div class="flex justify-between items-center align-middle mb-[10px]">
                <div>
                    <input :value="surname" @change="$emit('update:surname', $event.target.value)" class="text-[#FFFFFF] font-semibold text-[13px] outline-none bg-transparent border-none w-[90%] truncate placeholder:opacity-60" type="text" placeholder="Іваненко">
                </div>
                <div class="text-[#3A7E32] font-normal opacity-90 text-[11px]">
                    прізвище
                </div>
            </div>
            <div class="flex justify-between items-center align-middle mb-[10px]">
                <div>
                    <input :value="name" @change="$emit('update:name', $event.target.value)" class="text-[#FFFFFF] font-semibold text-[13px] outline-none bg-transparent border-none w-[90%] truncate placeholder:opacity-60" type="text" placeholder="Іван">
                </div>
                <div class="text-[#3A7E32] font-normal opacity-90 text-[11px]">
                    ім'я
                </div>
            </div>
            <div class="flex justify-between items-center align-middle mb-[10px]">
                <div class="w-[50%]">
                    <input :value="patronymic" @change="$emit('update:patronymic', $event.target.value)" class="text-[#FFFFFF] font-semibold text-[13px] outline-none bg-transparent border-none w-[70%] truncate placeholder:opacity-60" type="text" placeholder="Іванович">
                </div>
                <div class="text-[#3A7E32] font-normal opacity-90 text-[11px]">
                    по батькові
                </div>
            </div>
            <div class="flex justify-between items-center align-middle mb-[10px]">
                <div class="w-[50%]">
                    <input :value="formattedBirthDate" @change="formattedBirthDate = $event.target.value" class="text-[#FFFFFF] font-semibold text-[13px] outline-none bg-transparent border-none w-[80%] truncate placeholder:opacity-60" type="date">
                </div>
                <div class="text-[#3A7E32] font-normal opacity-90 text-[11px]">
                    дата народження
                </div>
            </div>
            <div class="flex justify-between items-center align-middle mb-[10px]">
                <div class="w-[70%]">
                    <input :value="phoneNumber" @change="$emit('update:phoneNumber', $event.target.value)" class="text-[#FFFFFF] font-semibold text-[13px] outline-none bg-transparent border-none w-[70%] truncate placeholder:opacity-60" type="text" placeholder="+380000000000">
                </div>
                <div class="text-[#3A7E32] font-normal opacity-90 text-[11px]">
                    номер
                </div>
            </div>
            <div class="flex justify-between items-center align-middle mb-[10px]">
                <div class="w-[70%]">
                    <input :value="email" @change="$emit('update:email', $event.target.value)" class="text-[#FFFFFF] font-semibold text-[13px] outline-none bg-transparent border-none w-[70%] truncate placeholder:opacity-60" type="text" placeholder="Email">
                </div>
                <div class="text-[#3A7E32] font-normal opacity-90 text-[11px]">
                    email
                </div>
            </div>
            <div class="flex justify-between items-center align-middle">
                <div class="w-[70%] text-[#FFFFFF] font-semibold text-[13px] outline-none bg-transparent border-none truncate">
                    {{nationality}}
                </div>
                <div class="text-[#3A7E32] font-normal opacity-90 text-[11px]">
                    національність
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { ref, onMounted, watch, computed } from 'vue'
    import DataInput from '../ui/DataInput.vue'
    
    function formateDate(date) {
        const dateObj = new Date(date)
        const year = dateObj.getFullYear()
        const month = dateObj.getMonth() + 1 > 9 ? dateObj.getMonth() + 1 : `0${dateObj.getMonth() + 1}`
        const day = dateObj.getDate() > 9 ? dateObj.getDate() : `0${dateObj.getDate()}`
        return `${year}-${month}-${day}`
    }

    export default {
        name: 'info-card',

        props: {
            surname: {
                type: String,
                default: '',
                required: true
            },
            name: {
                type: String,
                default: '',
                required: true
            },
            patronymic: {
                type: String,
                default: '',
                required: true
            },
            birthDate: {
                type: String,
                default: '',
                required: true
            },
            phoneNumber: {
                type: String,
                default: '',
                required: true
            },
            email: {
                type: String,
                default: '',
                required: true
            },
            trainerPhoto: {
                type: [String, null],
                default: null,
                required: false
            },
            nationality: {
                type: String,
                default: 'UKR',
                required: true
            }
        },

        setup(props, { emit }) {
            const fileInput = ref(null)
            const inputValue = ref(null)
            const photo = ref(props.trainerPhoto)

            const uploadPhoto = () => {
                fileInput.value.click()
            }

            const formattedBirthDate = computed({
                get() {
                    if (!props.birthDate?.includes('T'))
                        return props.birthDate
                    return formateDate(props.birthDate)
                },
                set(value) {
                    emit('update:birthDate', value)
                }
            })

            onMounted(() => {
                if(photo.value === null || photo.value === 'null') {
                    photo.value = require('@/assets/student_photo_placeholder.svg')
                }
            })

            watch(() => props.trainerPhoto, (first, second) => {
                photo.value = first
                inputValue.value = null
            })

            return {
                uploadPhoto,
                photo,
                fileInput,
                inputValue, 
                formattedBirthDate
            }
        },

        components: {
            DataInput
        },
    }
</script>

<style scoped>

    .student-logo {
        -webkit-filter: drop-shadow(0px 0px 10px #222);
        filter: drop-shadow(0px 0px 10px #222);
    }

    ::-webkit-calendar-picker-indicator {
        background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" viewBox="0 0 24 24"><path fill="%23FFFFFF" d="M20 3h-1V1h-2v2H7V1H5v2H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 18H4V8h16v13z"/></svg>');
    }

    .avenir-bold {
        font-family: 'Avenir Cyrillic Bold';
    }

    .avenir-standart {
        font-family: 'Avenir Cyrillic';
    }

</style>