<template>
    <div style="background-color: rgba(29, 29, 29, .35)" class="backdrop-blur-[7px] fixed w-full z-40 h-full top-0 -mt-2"></div>
    <div class="bg-[#2B2A29] border-[#47484966] rounded-[10px] z-50 absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] ml-[100px] border-b-[1px]">
        <div class="px-[20px] py-[20px] w-[355px]">
            <div class="flex justify-center"><img src="../../../../assets/logo.svg" class="h-[70px]"></div>
            <div class="font-semibold text-center text-[16px] text-white mb-[25px] mt-[10px]">
                Фільтри надходжень
            </div>
            <div class="w-full">
                <div class="text-[#8C8C8C] font-medium text-[14px]">
                    статус отримувача
                </div>
                <div class="mt-[3px]">
                    <select v-model="filters.receiver_type" class="w-full h-full border-[1px] border-[#9E9E9E] rounded-[6px] text-[#fff] bg-[#474849] bg-opacity-20 py-[7px] pl-[5px] outline-none">
                        <option value="all" class="bg-[#1D1D1D]">Всі</option>
                        <option value="Учень" class="bg-[#1D1D1D]">Учень</option>
                        <option value="Тренер" class="bg-[#1D1D1D]">Тренер</option>
                        <option value="Сервіс" class="bg-[#1D1D1D]">Сервіс</option>
                    </select>
                </div>
            </div>
            <div class="w-full mt-[20px]">
                <div class="text-[#8C8C8C] font-medium text-[14px]">
                    тип транзакції
                </div>
                <div class="mt-[3px]">
                    <select v-model="filters.transaction_type" class="w-full h-full border-[1px] border-[#9E9E9E] rounded-[6px] text-[#fff] bg-[#474849] bg-opacity-20 py-[7px] pl-[5px] outline-none">
                        <option value="all" class="bg-[#1D1D1D]">Всі</option>
                        <option value="Реальна" class="bg-[#1D1D1D]">Реальна</option>
                        <option value="Віртуальна" class="bg-[#1D1D1D]">Віртуальна</option>
                    </select>
                </div>
            </div>
            <div class="w-full mt-[20px]">
                <div class="text-[#8C8C8C] font-medium text-[14px]">
                    сума транзакції
                </div>
                <div class="flex justify-between mt-[3px]">
                    <div class="w-[48%] rounded-[6px] flex justify-between px-[10px] py-[4px] border-[1px] border-[#9E9E9E] bg-[#474849] bg-opacity-20 text-[#FFF]">
                        <span>Від:</span>
                        <input v-model="filters.amount_from" class="px-[5px] input-hide-arrows w-[50px] bg-transparent text-[#fff] outline-none" type="number" name="amount_from">
                        <span>грн.</span>
                    </div>
                    <div class="w-[48%] rounded-[6px] flex justify-between px-[10px] py-[4px] border-[1px] border-[#9E9E9E] bg-[#474849] bg-opacity-20 text-[#FFF]">
                        <span>До:</span>
                        <input v-model="filters.amount_to" class="input-hide-arrows px-[5px] w-[50px] bg-transparent text-[#fff] outline-none" type="number" name="amount_to">
                        <span>грн.</span>
                    </div>
                </div>
            </div>
            <div class="w-full mt-[20px]">
                <div class="text-[#8C8C8C] font-medium text-[14px]">
                    дата транзакції
                </div>
                <div class="flex justify-between mt-[3px]">
                    <div class="w-[48%]  rounded-[6px] flex px-[10px] py-[4px] border-[1px] border-[#9E9E9E] bg-[#474849] bg-opacity-20 text-[#FFF] items-center">
                        <span>Від:</span>
                        <input v-model="filters.date_from" class="custom-date-input bg-transparent pl-[5px] outline-none" type="date" name="date_from">
                    </div>
                    <div class="w-[48%] rounded-[6px] flex px-[10px] py-[4px] border-[1px] border-[#9E9E9E] bg-[#474849] bg-opacity-20 text-[#FFF] items-center">
                        <span>До:</span>
                        <input v-model="filters.date_to" class="custom-date-input bg-transparent  pl-[5px] outline-none" type="date" name="date_to">
                    </div>
                </div>
            </div>
            <!-- <div v-if="filters.current_transaction_id" class="w-full mt-[20px]">
                <div class="text-center mb-[3px]">
                    ID транзакції
                </div>
                <input :value="filters.current_transaction_id" type="text" class="w-full bg-gray-100 py-[5px] pl-[5px] outline-none disabled:text-gray-500" name="transaction_id" disabled>
            </div>
            <div v-if="filters.current_balance_id" class="w-full mt-[20px]">
                <div class="text-center mb-[3px]">
                    ID балансу
                </div>
                <input :value="filters.current_balance_id" type="text" class="w-full bg-gray-100 py-[5px] pl-[5px] outline-none disabled:text-gray-500" name="transaction_id" disabled>
            </div> -->
            <div class="w-full mt-[25px]">
                <div class="w-full flex justify-center">
                    <button @click="$emit('setFilters', filters)" class="bg-[#4EAE3B] text-white w-[260px] mx-auto font-medium h-[28px] rounded-[5px]">
                        Застосувати
                    </button>
                </div>
                <div class="w-full mt-[10px] flex justify-center">
                    <button @click="$emit('close')" class="w-[260px] bg-[#3B9BF4] font-medium h-[28px] mx-auto rounded-[5px] text-[#1D1D1D]">
                        Відмінити
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { ref, onMounted } from 'vue'

    export default {
        name: 'income-filter-popup',

        props: {
            filters: {
                type: Object,
                default: () => ({})
            }
        },

        setup(props) {
            const filters = ref(Object.assign({}, props.filters))

            return {
                filters
            }
        }
    }
</script>

<style lang="scss" scoped>
    input::-webkit-outer-spin-button, input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    input[type=number] {
        -moz-appearance: textfield;
    }

    @font-face {
        font-family: 'Fixel';
        src: url('@/assets/fonts/FixelDisplay/FixelDisplay-Regular.ttf');
    }

    .window {
        font-family: 'Fixel';
    }

    .custom-date-input::-webkit-calendar-picker-indicator {
        background-image: url('@/assets/icons/white_calendar.svg');
        cursor: pointer;
    }
</style>