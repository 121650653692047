<template>
    <div>
        <div class="flex justify-between">
            <div class="font-semibold text-[#fff]">
                ДОХОДИ ПО ГРУПАХ
            </div>
            <div>
                <select v-model="currentMonth" name="month" class="outline-none relative right-[17px] px-[5px] py-[0.6px] rounded-[4px] w-[144px] border-[#A4A6B3] text-[#fff] text-[13px] border-[1px] bg-transparent">
                    <option v-for="month in generateMonthesAndYear()" :value="month.value" class="bg-[#252526]">{{ month.label }}</option>
                </select>
            </div>
        </div>
        <BarOverlineChart 
            v-if="dataLoadedFlag"
            class="w-max relative left-[12px]"
            :data="chartData"
            :labels="chartLabels"
            :bottomBarColor="'#4EAE3B'"
            :topBarColor="'#3B9BF4'"
            :lineColor="'#F9245A'"
            :height="height"
            :width="width"
        />
        <div v-else class="placeholder-params relative">
            <div class="absolute top-[50%] left-[50%] transform translate-x-[-50%] translate-y-[-50%]">
                <div class="text-[14px] text-gray-400">Немає даних</div>
            </div>
        </div>
        <div class="flex justify-between w-max mx-auto mt-[10px]">
            <div class="flex items-center mx-3">
                <div class="w-5 h-2 bg-[#4EAE3B] rounded-2xl"></div>
                <div class="mx-2 text-[12px] text-[#9FA2B4]">готівка</div>
            </div>
            <div class="flex items-center mx-3">
                <div class="w-5 h-2 bg-[#3B9BF4] rounded-2xl"></div>
                <div class="mx-2 text-[12px] text-[#9FA2B4]">безготівка</div>
            </div>
            <div class="flex items-center mx-3">
                <div class="w-5 h-2 bg-[#F9245A] rounded-2xl"></div>
                <div class="mx-2 text-[12px] text-[#9FA2B4]">загальний дохід</div>
            </div>
        </div>
    </div>
</template>

<script>
    import { ref, onMounted, watch } from 'vue'
    import { getIncomesByGroups } from '@/services/apiRequests'
    import BarOverlineChart from '../BarOverlineChart.vue'

    export default {
        name: 'IncomesByGroups',

        setup() {
            const chartData = ref([])
            const chartLabels = ref([])
            const dataLoadedFlag = ref(false)

            const screenWidth = ref(window.innerWidth)
            const height = ref(230)
            const width = ref(864)

            const currentMonth = ref(0)
            const getMonthByNumber = (number) => {
                switch (number) {
                    case 1:
                        return 'січень'
                    case 2:
                        return 'лютий'
                    case 3:
                        return 'березень'
                    case 4:
                        return 'квітень'
                    case 5:
                        return 'травень'
                    case 6:
                        return 'червень'
                    case 7:
                        return 'липень'
                    case 8:
                        return 'серпень'
                    case 9:
                        return 'вересень'
                    case 10:
                        return 'жовтень'
                    case 11:
                        return 'листопад'
                    case 12:
                        return 'грудень'
                }
            }

            const generateMonthesAndYear = () => {
                const monthes = []
                const year = new Date().getFullYear()
                for (let i = 0; i < 12; i++) {
                    monthes.push({
                        value: `${i + 1} ${year}`,
                        label: `${getMonthByNumber(i + 1)} ${year}`
                    })
                }
                return monthes
            }

            const getCurrentMonth = () => {
                const date = new Date()
                const month = date.getMonth()
                const year = date.getFullYear()

                return `${month + 1} ${year}`
            }

            onMounted(async () => {
                currentMonth.value = getCurrentMonth()
                const year = new Date().getFullYear()
                const month = new Date().getMonth() + 1
                const res = (await getIncomesByGroups(year, month))[0]
                console.log('res -----!---- ', res)

                const sumAllIncomes = res.groups.reduce((acc, group) => {
                    return acc + group.cash_incomes + group.cashless_incomes
                }, 0)
                
                if (sumAllIncomes === 0) {
                    return
                }

                res.groups.forEach((group) => {
                    chartData.value.push([group.cash_incomes, group.cashless_incomes])
                    chartLabels.value.push(group.group_name)
                })
                console.log('chartLabels', chartLabels.value)
                dataLoadedFlag.value = true

                window.addEventListener('resize', () => {
                    screenWidth.value = window.innerWidth
                })

                if (screenWidth.value < 1410) {
                    width.value = 900
                } else {
                    width.value = 1070
                }
            })

            watch(currentMonth, async (newValue) => {
                dataLoadedFlag.value = false
                chartData.value = []
                console.log('newValue', newValue)
                const [month, year] = newValue.split(' ')
                const res = (await getIncomesByGroups(year, month))[0]

                const sumAllIncomes = res.groups.reduce((acc, group) => {
                    return acc + group.cash_incomes + group.cashless_incomes
                }, 0)
                
                if (sumAllIncomes === 0) {
                    return
                }
                
                res.groups.forEach((group) => {
                    chartData.value.push([group.cash_incomes, group.cashless_incomes])
                    chartLabels.value.push(group.group_name)
                })
                dataLoadedFlag.value = true
            })

            watch(screenWidth, (newValue) => {
                if (newValue < 1410) {
                    width.value = 900
                } else {
                    width.value = 1070
                }
            })

            return {
                chartData,
                dataLoadedFlag,
                currentMonth,
                generateMonthesAndYear,
                getCurrentMonth,
                chartLabels,
                screenWidth,
                height,
                width
            }
        },

        components: {
            BarOverlineChart,
        }
    }
</script>

<style lang="scss" scoped>

    .placeholder-params {
        @apply h-[230px] w-[1070px];
    }

    @media (min-width: 1410px) and (max-width: 8000px) {
        .placeholder-params {
            @apply h-[230px] w-[1070px];
        }
    }

    @media (min-width: 0px) and (max-width: 1410px) {
        .placeholder-params {
            @apply h-[230px] w-[900px];
        }
    }

</style>