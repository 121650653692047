<template>
    <div @click="processExit" style="background-color: rgba(29, 29, 29, .35)" class="backdrop-blur-[10px] fixed w-full h-[130%] top-[-70px] -mt-2 z-20"></div>
    <exit-confirm-form
        v-if="exitConfirmFlag && loadedData"
        :alertText="'Бажаєте зберегти зміни у картці групи ' + groupName"
        class="absolute top-[50%] ml-[110px] z-40 w-[260px] left-[50%] translate-x-[-50%] translate-y-[-50%]"

        @returnToCurrent="exitConfirmFlag = false"
        @dontSave="$emit('close')"
        @doSave="updateGroupVM()"
    />
    <select-students-form 
        v-if="selectStudentsFlag"
        :group="group"
        :trainer="trainer"
        class="z-40"
        @close="selectStudentsFlag = false; updateGroup()"
    />
    <div v-if="!loadedData" class="w-10 h-10 rounded-full animate-spin border-solid border-4 border-[#F9245A] border-t-transparent absolute top-[50%] left-[50%] ml-[90px] z-40"></div>
    <div v-if="!exitConfirmFlag && loadedData && !selectStudentsFlag" class="z-40">

        <div style="box-shadow: 4px 4px 4px 0px #00000040;" class="border-[1px] border-[#8C8C8C33] bg-[#252526] pt-[23px] rounded-[10px] px-[15px] w-[291px] h-[806px] absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] ml-[100px] z-50">
            <div class="flex justify-center mb-[15px]"><img src="../../../assets/logo.svg" class="h-[70px]"></div>
            <div class="font-semibold text-center px-[20px] text-[24px] text-[#fff] leading-[19px] mb-[10px]">{{ groupName }}</div>
            <div class="font-light text-center px-[20px] text-[16px] text-[#fff] leading-[19px] mb-[10px]">(Учнів - {{ studentsCount }})</div>

            <div class="w-full">
                <div class="text-[#8C8C8C] text-center font-medium text-[14px]">
                    назва нової групи
                </div>
                <div class="mt-[3px]">
                    <input 
                        v-model="groupName"
                        class="w-full border-[1px] border-[#9E9E9E] rounded-[6px] text-[#fff] bg-[#474849] bg-opacity-20 text-[13px] py-[3px] pl-[5px] outline-none"
                        type="text" 
                        name="name"
                    >
                </div>
            </div>

            <div class="w-full mt-[20px]">
                <div class="text-[#8C8C8C] text-center font-medium text-[14px]">
                    діапазон років народження учнів
                </div>
                <div class="mt-[3px]">
                    <input 
                        v-model="groupPeriods"
                        class="w-full border-[1px] border-[#9E9E9E] rounded-[6px] text-[#fff] bg-[#474849] bg-opacity-20 text-[13px] py-[3px] pl-[5px] outline-none"
                        type="text" 
                        name="name"
                    >
                </div>
            </div>

            <div class="w-full mt-[20px]">
                <div class="text-[#8C8C8C] text-center font-medium text-[14px]">
                    оберіть тренера зі списку
                </div>
                <select :value="trainerId" @change="e => selectNewTrainer(e.target.value)" class="mt-[3px] w-full border-[1px] border-[#9E9E9E] rounded-[6px] text-[#fff] bg-[#474849] bg-opacity-20 text-[13px] py-[5.5px] pl-[5px] outline-none" name="to_balance">
                    <option class="bg-[#252526] text-[#fff]" value="" disabled>Виберіть тренера</option>
                    <option v-for="trainer in trainers" :value="trainer.trainer_id" class="bg-[#252526] text-[#fff]">{{ trainer.name + ' ' + trainer.surname }}</option>
                </select>
            </div>  

            <div class="w-full mt-[10px]">
                <input v-model="assistantQuery" class="mt-[3px] w-full border-[1px] border-[#9E9E9E] rounded-[6px] text-[#fff] bg-[#474849] bg-opacity-20 text-[13px] py-[4px] pl-[5px] outline-none" placeholder="Ім'я асистента">
                <div class="absolute w-[90%] z-[50]">
                    <div v-for="trainer in visibleAssistants" @click="addAssistantToGroup(trainer.trainer_id)" class="bg-[#9E9E9E] px-[5px] py-[2px] cursor-pointer hover:bg-[#929292] w-full">{{ trainer.name + ' ' + trainer.surname }}</div>
                </div>
                
                <div class="mt-[10px] flex h-[25px] w-[100%] overflow-x-auto scrolltab">
                    <div v-if="currentAssistants.length == 0" class="text-center w-full text-white opacity-30">Асистенти не вибрані</div>
                    <div v-for="trainer in currentAssistants" class="mr-[5px]" @click="removeAssistantFromGroup(trainer)">
                        <div class="px-[7px] flex items-center justify-between py-[1px] bg-[#9E9E9E] truncate rounded-full text-[11px] w-[90px] text-center">
                            <div class="truncate w-[70%]">{{ getTrainerName(trainer) }}</div>
                            <img src="@/assets/icons/cross-icon.svg" class="w-[10px] rotate-45 cursor-pointer">
                        </div>
                    </div>
                </div>

            </div>  

            <div class="w-full mt-[10px] flex items-center justify-between">
                <div class="text-[#8C8C8C] text-center font-medium relative top-[5px] text-[14px]">
                    Група 
                    <span v-if="groupPaymentStatusBool" class="text-[#41BF74]">платна</span>
                    <span v-else class="text-[#FA1367]">безоплатна</span>
                </div>
                <div>
                    <IphoneSwitch :isOnValue="groupPaymentStatusBool" @change="changeGroupPaymentStatus"/>
                </div>
            </div>

            <div class="relative mx-auto h-[234px] border-[1px] border-[#9E9E9E] mt-[20px] rounded-[6px]">
                <div v-if="selectTrainerFlag" class="w-full h-full relative">
                    <div class="flex justify-center items-center h-full">
                        <div class="text-[#8C8C8C] text-center font-medium text-[14px]">
                            інформація про тренера
                        </div>
                    </div>
                    <div class="absolute w-[80px] h-[1px] bg-[#8C8C8C] top-[40px] rotate-[45deg]"></div>
                    <div class="absolute w-[80px] h-[1px] bg-[#8C8C8C] bottom-[40px] right-0 rotate-[45deg]"></div>
                    <div class="absolute w-[80px] h-[1px] bg-[#8C8C8C] bottom-[40px] left-0 rotate-[-45deg]"></div>
                    <div class="absolute w-[80px] h-[1px] bg-[#8C8C8C] top-[40px] right-0 rotate-[-45deg]"></div>
                </div>

                <div v-else class="absolute w-full bottom-0">
                    <div class="h-[150px] w-full flex justify-center -z-10">
                        <img :key="trainerPictureKey" :src="trainerPicture" @error="$event.target.src = require('@/assets/photo-placeholder-big.png')" class="-z-10 relative ">
                    </div>
                    <div class="bg-[#4EAE3B] w-full py-1 mt-[-20px] z-20 text-white">
                        <div class="font-medium text-[23px] text-center">{{ trainer ? trainer.name ? trainer.name + ' ' + trainer.surname : "Тренер" : "Тренер" }}</div>
                        <div class="font-medium text-[23px] relative bottom-[3px] text-center">{{ groupName.length > 0 ? groupName : 'Група' }}</div>
                        <div class="text-center">({{ groupPeriods ? groupPeriods : "? - ?" }})</div>
                    </div>
                </div>
            </div>

            <button @click="selectStudentsFlag = true" class="w-full rounded-[6px] bg-[#4EAE3B] text-[13px] text-[#11204D] py-[6px] mt-[20px]">
                Управління учнями
            </button>
        </div>
    </div>


</template>

<script>
    import { ref, onMounted, watch } from 'vue'
    import { uuid } from 'vue3-uuid'
    import { getTrainerById, getGroupById, getStudentsCountByGroupId, updateGroupRequest, getAllTrainers } from '@/services/apiRequests'

    import DataInput from '../ui/DataInput.vue'
    import CoachSelectionForm from '../ui/CoachSelectionForm.vue'
    import SelectStudentsForm from './SelectStudentsForm.vue'
    import IphoneSwitch from '../ui/IphoneSwitch.vue'

    import ExitConfirmForm from '../ui/ExitConfirmForm.vue'

    export default {
        name: 'group-full-card',

        props: {
            group: {
                type: Object,
                required: true
            }, 
            allTrainers: {
                type: Array,
                required: true
            }
        },

        setup(props, { emit }) {
            const selectStudentsFlag = ref(false)
            
            const groupClone = ref(Object.assign({}, props.group))
            
            const groupName = ref(groupClone.value.name)
            const groupPeriods = ref(groupClone.value.year_range)
            
            const trainer = ref({})
            const trainerPicture = ref('')

            let bufferGroupName = ''
            let bufferGroupPeriods = ''
            let bufferGroupPaymentStatusText = ''
            let bufferGroupTrainer = {}

            const trainerFullName = ref('')

            const trainers = ref([])
            const trainerId = ref('')
            const trainerPictureKey = ref(uuid.v4())

            const groupPaymentStatusBool = ref(props.group.payment_status && props.group.payment_status == 'paid' ? true : false)
            const groupPaymentStatusText = ref(props.group.payment_status && props.group.payment_status == 'paid' ? 'paid' : 'free')

            const loadedData = ref(false)

            const assistantQuery = ref('')
            const visibleAssistants = ref([])
            const bufferedAssistants = ref(Object.assign([], props.group.assistants))
            const currentAssistants = ref(Object.assign([], props.group.assistants))

            const updateGroupVM = () => {
                console.log('request trainer', trainer.value)
                updateGroupRequest(
                    props.group.group_id,
                    groupName.value,
                    groupPeriods.value,
                    trainer.value.trainer_id,
                    groupPaymentStatusText.value,
                    currentAssistants.value
                ).then(res => {
                    console.log(res)
                    if (res.status === 200) {
                        emit('close')
                        emit('updateGroup')
                    }
                })
            }

            const processExit = () => {
                if ((bufferGroupName !== groupName.value || bufferGroupPeriods !== groupPeriods.value || bufferGroupTrainer?.trainer_id !== trainer.value?.trainer_id || bufferGroupPaymentStatusText !== groupPaymentStatusText.value || bufferedAssistants.value !== currentAssistants.value) && loadedData.value) {
                    exitConfirmFlag.value = true
                } else {
                    emit('close')
                }
            }

            const selectNewTrainerFlag = ref(false)

            const exitConfirmFlag = ref(false)

            const selectNewTrainer = (newTrainerId) => {
                const newTrainer = trainers.value.find(trainer => trainer.trainer_id == newTrainerId)
                trainer.value = newTrainer
                trainerPicture.value = process.env.VUE_APP_API_URL + 'static/' + newTrainer.photo
                selectNewTrainerFlag.value = false
                trainerFullName.value = trainer.value.name + ' ' + trainer.value.surname
                trainerPictureKey.value = uuid.v4()
            }
            
            const studentsCount = ref(0)

            const updateGroup = async () => {
                const res = await getGroupById(props.group.group_id)
                groupClone.value = res.data
                const studentsCountRes = await getStudentsCountByGroupId(props.group.group_id)
                studentsCount.value = studentsCountRes.data
            }

            onMounted(async () => {
                try {
                    const res = await getTrainerById(props.group.trainer_id)
                    trainer.value = res.data
                } catch (e) {
                    trainer.value = null
                }
                const studentsCountRes = await getStudentsCountByGroupId(props.group.group_id)
                studentsCount.value = studentsCountRes.data
                trainerFullName.value = trainer.value?.name + ' ' + trainer.value?.surname
                trainerPicture.value = process.env.VUE_APP_API_URL + 'static/' + trainer.value?.photo
                trainerId.value = trainer.value?.trainer_id
            
                bufferGroupName = groupName.value
                bufferGroupPeriods = groupPeriods.value
                bufferGroupTrainer = Object.assign({}, trainer.value)
                bufferGroupPaymentStatusText = groupPaymentStatusText.value + ''

                const response = await getAllTrainers()
                response.data.forEach(trainer => {
                    trainers.value.push({
                        trainer_id: trainer.trainer_id,
                        name: trainer.name,
                        surname: trainer.surname,
                        photo: trainer.photo
                    })
                })

                loadedData.value = true
            })

            const changeGroupPaymentStatus = () => {
                groupPaymentStatusBool.value = !groupPaymentStatusBool.value
                if (groupPaymentStatusBool.value) {
                    groupPaymentStatusText.value = 'paid'
                } else {
                    groupPaymentStatusText.value = 'free'
                }
            }

            watch(assistantQuery, () => {
                if (assistantQuery.value.length > 0)
                    visibleAssistants.value = props.allTrainers.filter(t => t.name.toLowerCase().includes(assistantQuery.value.toLowerCase()) || t.surname.toLowerCase().includes(assistantQuery.value.toLowerCase()))
                else
                    visibleAssistants.value = []
            })

            const addAssistantToGroup = (trainer_id) => {
                currentAssistants.value.push(trainer_id)
                assistantQuery.value = ''
            }

            const removeAssistantFromGroup = (trainer_id) => {
                currentAssistants.value = currentAssistants.value.filter(t => t != trainer_id)
            } 

            const getTrainerName = (trainer_id) => {
                const trainer = props.allTrainers.find(t => t.trainer_id === trainer_id)

                return trainer.surname + ' ' + trainer.name[0] + '.' 
            }

            return {
                selectStudentsFlag,
                groupName,
                groupPeriods,
                trainer,
                updateGroup,
                studentsCount,
                trainerPicture,
                selectNewTrainer,
                selectNewTrainerFlag,
                trainerFullName,
                exitConfirmFlag,
                processExit,
                updateGroupVM,
                loadedData,
                trainers,
                trainerId,
                trainerPictureKey,
                groupPaymentStatusBool,
                groupPaymentStatusText,
                changeGroupPaymentStatus,
                assistantQuery,
                visibleAssistants,
                currentAssistants,
                addAssistantToGroup,
                getTrainerName,
                removeAssistantFromGroup
            }
        },

        components: {
            DataInput,
            CoachSelectionForm,
            SelectStudentsForm,
            ExitConfirmForm,
            IphoneSwitch
        }
    }
</script>

<style lang="scss">

    .scrolltab::-webkit-scrollbar {
        width: 5px;               /* ширина всей полосы прокрутки */
    }

    .scrolltab::-webkit-scrollbar-track {
        background: transparent;        /* цвет зоны отслеживания */
    }

    .scrolltab::-webkit-scrollbar-thumb {
        background-color: #a6a4a4;    /* цвет бегунка */
        border-radius: 20px;       /* округлось бегунка */ /* отступ вокруг бегунка */
    }

</style>