<template>
  <router-view />
</template>

<style lang="scss">
  @tailwind base;
  @tailwind components;
  @tailwind utilities;

  body {
    background-color: #1D1D1D;
    overflow-x: hidden;
  }

  body::-webkit-scrollbar {
    width: 5px;               /* ширина всей полосы прокрутки */
  }

  body::-webkit-scrollbar-track {
    background: transparent;        /* цвет зоны отслеживания */
  }

  body::-webkit-scrollbar-thumb {
    background-color: #ABD3EC;    /* цвет бегунка */
    border-radius: 20px;       /* округлось бегунка */ /* отступ вокруг бегунка */
  }

  @font-face {
    font-family: 'Helvetica Bold';
    src: url('@/assets/fonts/helvetica_bold.otf');
  }

  @font-face {
    font-family: 'Helvetica Regular';
    src: url('@/assets/fonts/helvetica_regular.otf');
  }

  @font-face {
    font-family: 'Avenir Roman';
    src: url('@/assets/fonts/MaybeAvenir.ttf');
  }

  @font-face {
    font-family: 'Avenir Roman Bold';
    src: url('@/assets/fonts/AvenirLTStd-Black.otf');
  }

  @font-face {
    font-family: 'Avenir Cyrillic';
    src: url('@/assets/fonts/MaybeAvenir.ttf');
  }

  @font-face {
    font-family: 'Avenir Cyrillic Bold';
    src: url('@/assets/fonts/avenirnextcyr-bold.ttf');
  }

  /* Убираем стрелки в Safari */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    appearance: none;
    margin: 0;
}


/* Убираем стрелки в Chrome */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    appearance: none;
    margin: 0;
}

/* Убираем стрелки во всех браузерах */
input[type="number"] {
    -webkit-appearance: none;
    -moz-appearance: textfield;
}

</style>