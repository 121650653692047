<template>
    <tr class="text-white">
        <td class="w-[2%] px-3 py-2">{{ id + 1 }}.</td>
        <td class="w-[10%] px-3 py-2 truncate">{{ outlay.name }}</td>
        <td class="w-[17%] px-3 py-2 truncate">{{ outlay.category_name?.length > 0 ? outlay.category_name : 'Не задано' }}</td>
        <td class="w-[13%] px-3 py-2 truncate">{{ outlay.subcategory_name?.length > 0 ? outlay.subcategory_name : 'Не задано' }}</td>
        <td class="w-[7%] px-3 py-2 text-[#F10265]">{{ outlay.amount.toFixed(2) }}</td>
        <td class="w-[10%] px-3 py-2 text-[#4EAE3B]">{{ outlayType }}</td>
        <td v-if="screenWidth > 1700" class="w-[16%] px-3 py-2 truncate">{{ outlay.creator_name }}</td>
        <td class="w-[10%] px-3 py-2">{{ outlay.date.split('T')[0].split('-').reverse().join('.') }}</td>
        <td class="w-[15%] px-3 py-2">
            <div class="flex justify-end items-center">
                <img @click="toggleInfo" src="@/assets/icons/invoice_icon.svg" class="cursor-pointer mr-[10px]">
                <img @click="$emit('update')" src="@/assets/icons/pen_icon.svg" class="cursor-pointer mr-[10px]">
                <img @click="$emit('delete')" src="@/assets/icons/trash_icon.svg" class="cursor-pointer mr-[10px]">
            </div>
        </td>
    </tr>
    <tr v-if="showInfo">
        <td colspan="9" class="border-b-[1px] pt-2 pb-4 text-white px-3 border-[#2D3B48]">
            <div class="flex">
                <div class="w-[37%] pr-4">
                    <div>Назва витрати: <i>"{{ outlay.name }}"</i></div>
                    <div class="mt-4">Категорія: <i>"{{ outlay.category_name?.length > 0 ? outlay.category_name : 'Не задано' }}"</i></div>
                    <div class="mt-1">Підкатегорія: <i>"{{ outlay.subcategory_name?.length > 0 ? outlay.subcategory_name : 'Не задано' }}"</i></div>

                    <div 
                        v-if="outlay.additional_files.length > 0"
                        class="w-full mt-[15px]"
                    >
                        <div class="font-semibold">
                            Прікріплені файли
                        </div>
                        <div class="mt-[5px]">
                            <div v-for="(file, id) in outlay.additional_files" @click="$emit('showPicture', file)" class="w-[70px] text-[14px] text-center bg-gray-600 hover:bg-opacity-80 rounded-lg cursor-pointer text-white">Файл {{ id + 1 }}</div>
                        </div>
                    </div>
                </div>
                <div class="w-[37%]">
                    <div>Сума: <span class="text-[#F10265]">{{ outlay.amount.toFixed(2) }}</span></div>
                    <div class="mt-4">З балансу: <b>{{ outlay.sender_balance_name }}</b></div>
                    <div class="mt-1">Витрату вніс: <b>{{ outlay.creator_name }}</b></div>
                </div>
                <div class="w-[26%]">
                    <div>Коментар:</div>
                    <div>
                        <i>
                            {{ outlay.label }}
                        </i>
                    </div>
                </div>
            </div>
        </td>
    </tr>
</template>

<script>
    import { ref, onMounted, watch } from 'vue'

    export default {
        name: 'club-outlay-row',

        props: {
            outlay: {
                type: Object,
                required: true
            },
            id: {
                type: Number,
                required: true
            }
        },

        setup(props) {
            const showInfo = ref(false)
            const outlayType = ref('')
            const screenWidth = ref(window.innerWidth)

            const toggleInfo = () => {
                showInfo.value = !showInfo.value
            }

            watch(() => window.innerWidth, () => {
                screenWidth.value = window.innerWidth
            })

            onMounted(() => {
                if (props.outlay.sender_balance_name.includes('Готівковий')) {
                    outlayType.value = 'готівка'
                } else {
                    outlayType.value = 'безготівка'
                }
            })

            return {
                showInfo,
                toggleInfo,
                outlayType,
                screenWidth
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>