<template>
    <div class="chart-container w-max">
      <canvas ref="canvas" class="mx-auto" height="70" width="330"></canvas>
    </div>
  </template>
  
<script>
  function formatNumber(num) {
    const absNum = Math.abs(num);

    if (absNum >= 1e9) {
      return (num / 1e9).toFixed(1) + 'G';
    } else if (absNum >= 1e6) {
      return (num / 1e6).toFixed(1) + 'M';
    } else if (absNum >= 1e3) {
      return (num / 1e3).toFixed(1) + 'K';
    } else {
      return num.toString();
    }
  }

  export default {
    name: 'BarChart',
  
    props: {
      data: {
        type: Array,
        required: true
      },
      barColor: {
        type: String,
        default: '#4EAE3B'
      },
      lables: {
        type: Array,
        required: true
      }
    },
  
    mounted() {
      this.drawChart();
    },
  
    methods: {
      drawChart() {
        const canvas = this.$refs.canvas;
        const ctx = canvas.getContext('2d');
        const height = canvas.height;
        const width = canvas.width;
        const max = Math.max(...this.data) > 0 ? Math.max(...this.data) : 21;
        const barWidth = width / (this.data.length * 1.4);
        const spacing = 2;
        const marginBottom = 25;
        const marginTop = 25;
        const barMaxHeight = height - (marginBottom + marginTop);

        ctx.beginPath();
        this.data.forEach((bar, i) => {
            const buff = bar;

            console.log('bar', bar)

            const currentBarPercentFromFull = (100 * bar) / max;

            const currentBlockFullHeight = (barMaxHeight * currentBarPercentFromFull) / 100 > 0 ? (barMaxHeight * currentBarPercentFromFull) / 100 : 1;

            ctx.fillStyle = this.barColor;
            ctx.fillRect(
                (i * barWidth * 1.4) + spacing,
                height - currentBlockFullHeight - marginBottom + 5,
                barWidth,
                currentBlockFullHeight
            );


            let labelX = ((i * barWidth * 1.4) + spacing) + (barWidth / 2);
            let labelY = height ;
            let labelText = buff;
            ctx.fillStyle = '#4EAE3B';
            ctx.font = 'bold 12px Arial';
            ctx.textAlign = 'center';
            labelX = ((i * barWidth * 1.4) + spacing) + (barWidth / 2);
            labelY = height - currentBlockFullHeight - marginBottom;
            labelText = formatNumber(buff);
            ctx.fillText(labelText, labelX, labelY);
            
        });
        ctx.stroke();

        
      }
    }
  };
</script>
  
<style scoped>
    .chart-container {
        width: 100%;
        height: 100%;
    }
</style>
