<template>
    <div>
        <div @click="closeWindows" style="background-color: rgba(29, 29, 29, .35)" class="absolute w-full h-full "></div>
        <ExitConfirmForm 
            class="absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]"
            v-if="exitFlag"
            :alertText="'Зберегти зміни для ' + editedAdmin.admin_name + ' ' + editedAdmin.admin_surname + '?'"
            @dontSave="() => $emit('close')"
            @doSave="saveAdmin"
            @returnToCurrent="exitFlag = false"
        />
        <div v-if="!exitFlag" class="absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] ml-[100px]">
            <AdminCard 
                v-model:name="editedAdmin.admin_name"
                v-model:surname="editedAdmin.admin_surname"
                v-model:cashBalance="cashBalanceState"
                v-model:investBalance="investBalanceState"
                v-model:birthDate="editedAdmin.birthday"
                v-model:email="editedAdmin.email"
                v-model:phone="editedAdmin.phone"
                v-model:login="editedAdmin.admin_login"
                v-model:password="editedAdmin.admin_password"
            />
        </div>
    </div>
</template>

<script>
    import { ref, onMounted } from 'vue'
    import { getBalanceState, updateAdminCard } from '@/services/apiRequests'
    import AdminCard from './AdminCard.vue'
    import ExitConfirmForm from './ui/ExitConfirmForm.vue'

    export default {
        name: 'admin-card',

        props: {
            admin: {
                type: Object,
                default: () => {}
            }
        },

        setup(props, { emit }) {
            const cashBalanceState = ref(0)
            const investBalanceState = ref(0)
            const bufferedAdmin = ref(Object.assign({}, props.admin))
            const editedAdmin = ref(Object.assign({}, props.admin))
            const exitFlag = ref(false)

            onMounted(async () => {
                // editedAdmin.value.birthday = editedAdmin.value.birthday.split('T')[0]
                // bufferedAdmin.value.birthday = bufferedAdmin.value.birthday.split('T')[0]
                const cashBalanceRes = await getBalanceState(props.admin.cash_balance_id)
                console.log(cashBalanceRes)
                cashBalanceState.value = cashBalanceRes
                investBalanceState.value = (await getBalanceState(props.admin.invest_balance_id))
            })

            const saveAdmin = async () => {
                editedAdmin.value.birthday = new Date(editedAdmin.value.birthday)
                await updateAdminCard(editedAdmin.value)
                exitFlag.value = false
                emit('close')
                emit('update-admins')
            }

            const closeWindows = () => {
                if (JSON.stringify(bufferedAdmin.value) !== JSON.stringify(editedAdmin.value)) {
                    exitFlag.value = true
                } else {
                    emit('close')
                }
            }
          
            return {
                cashBalanceState,
                investBalanceState,
                editedAdmin,
                closeWindows,
                exitFlag,
                saveAdmin
            }
        },

        components: {
            AdminCard,
            ExitConfirmForm
        }
    }
</script>

<style lang="scss" scoped>

</style>